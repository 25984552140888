var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sessions-table-component"},[(_vm.roomsThisWeek.length > 0)?_c('table',{staticClass:"sessions-table-component__this-week"},[_c('tr',[_c('th',[_c('font-awesome-icon',{attrs:{"icon":"fa-calendar","aria-hidden":"true","role":"presentation"}}),_vm._v("\n        This week's Schedule\n      ")],1)]),_vm._v(" "),_c('tr',_vm._l((_vm.roomsThisWeek),function(room,uuid){return _c('td',{key:uuid},[_c('h3',[_vm._v(_vm._s(room.name))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(room.descriptive_start_date)+" at "+_vm._s(room.casual_start_time)+"\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.short_description),expression:"room.short_description"}]},[_vm._v("\n          "+_vm._s(room.short_description.slice(0, 130))+"...\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.participant_limit && room.participant_limit == null),expression:"room.participant_limit && room.participant_limit == null"}],staticClass:"participant-count"},[_vm._v("\n          "+_vm._s(room.participant_count)+" participants so far\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.participant_limit && room.participant_limit !== null),expression:"room.participant_limit && room.participant_limit !== null"}],staticClass:"participant-count"},[_vm._v("\n          "+_vm._s(room.participant_count)+" /\n          "+_vm._s(room.participant_limit)+" participants\n        ")]),_vm._v(" "),_c('span',{staticClass:"video-conferencing__session-card-buttons"},[(!room.bookable)?_c('p',{staticClass:"video-conferencing__session-card-non-bookable"},[_vm._v("\n            You must be logged in to book this session\n          ")]):_vm._e(),_vm._v(" "),(
              !room.current_user_booked &&
              (room.participant_limit === null ||
                room.participant_count < room.participant_limit) &&
              room.bookable
            )?_c('router-link',{staticClass:"video-conferencing__session-book",attrs:{"to":'/book/' + room.uuid,"tag":"button","role":"button","tabindex":"0"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnterKey.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.handleSpaceKey.apply(null, arguments)}]}},[_vm._v("\n            Book Now\n          ")]):_vm._e(),_vm._v(" "),(
              room.current_user_booked &&
              !_vm.shouldShowCancelBookingWarning &&
              !room.joinable
            )?_c('button',{staticClass:"video-conferencing__session-cancel",on:{"click":function($event){return _vm.prepareToCancelBooking()}}},[_vm._v("\n            Cancel Booking\n          ")]):_vm._e(),_vm._v(" "),(room.current_user_booked && _vm.shouldShowCancelBookingWarning)?_c('button',{staticClass:"video-conferencing__session-cancel",on:{"click":function($event){return _vm.cancelBooking(room)}}},[_vm._v("\n            Are you sure?\n          ")]):_vm._e(),_vm._v(" "),(room.current_user_booked && room.joinable)?_c('button',{staticClass:"video-conferencing__session-join",on:{"click":function($event){return _vm.joinRoom(room)}}},[_vm._v("\n            Join Session\n          ")]):_vm._e(),_vm._v(" "),(
              room.participant_limit === null ||
              room.participant_count < room.participant_limit ||
              room.current_user_booked
            )?_c('button',{staticClass:"video-conferencing__session-more",on:{"click":function($event){return _vm.toggleIndividualSessionModal(room)}}},[_vm._v("\n            More Info\n          ")]):_vm._e(),_vm._v(" "),(
              !room.current_user_booked &&
              room.participant_limit &&
              room.participant_count === room.participant_limit
            )?[_c('p',{staticClass:"video-conferencing__session-full"},[_vm._v("Full!")])]:_vm._e()],2)])}),0)]):_vm._e(),_vm._v(" "),(_vm.roomsNextWeek.length > 0)?_c('table',{staticClass:"sessions-table-component__next-week"},[_c('tr',[_c('th',[_c('font-awesome-icon',{attrs:{"icon":"fa-calendar","aria-hidden":"true","role":"presentation"}}),_vm._v("\n        Next Week's Schedule\n      ")],1)]),_vm._v(" "),_c('tr',_vm._l((_vm.roomsNextWeek),function(room,uuid){return _c('td',{key:uuid},[_c('h3',[_vm._v(_vm._s(room.name))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(room.descriptive_start_date)+" at "+_vm._s(room.casual_start_time)+"\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.short_description),expression:"room.short_description"}]},[_vm._v("\n          "+_vm._s(room.short_description.slice(0, 130))+"...\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.participant_limit && room.participant_limit == null),expression:"room.participant_limit && room.participant_limit == null"}],staticClass:"participant-count"},[_vm._v("\n          "+_vm._s(room.participant_count)+" participants so far\n        ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(room.participant_limit && room.participant_limit !== null),expression:"room.participant_limit && room.participant_limit !== null"}],staticClass:"participant-count"},[_vm._v("\n          "+_vm._s(room.participant_count)+" /\n          "+_vm._s(room.participant_limit)+" participants\n        ")]),_vm._v(" "),_c('span',[(!room.bookable)?_c('p',{staticClass:"video-conferencing__session-card-non-bookable"},[_vm._v("\n            You must be logged in to book this session\n          ")]):_vm._e(),_vm._v(" "),(
              !room.current_user_booked &&
              (room.participant_limit === null ||
                room.participant_count < room.participant_limit) &&
              room.bookable
            )?_c('router-link',{staticClass:"video-conferencing__session-book",attrs:{"to":'/book/' + room.uuid,"tag":"button"}},[_vm._v("\n            Book\n          ")]):_vm._e(),_vm._v(" "),(room.current_user_booked && !_vm.shouldShowCancelBookingWarning)?_c('button',{staticClass:"video-conferencing__session-cancel",on:{"click":function($event){return _vm.prepareToCancelBooking()}}},[_vm._v("\n            Cancel Booking\n          ")]):_vm._e(),_vm._v(" "),(room.current_user_booked && _vm.shouldShowCancelBookingWarning)?_c('button',{staticClass:"video-conferencing__session-cancel",on:{"click":function($event){return _vm.cancelBooking(room)}}},[_vm._v("\n            Are you sure?\n          ")]):_vm._e(),_vm._v(" "),(
              room.participant_limit === null ||
              room.participant_count < room.participant_limit ||
              room.current_user_booked
            )?_c('button',{staticClass:"video-conferencing__session-more",on:{"click":function($event){return _vm.toggleIndividualSessionModal(room)}}},[_vm._v("\n            More\n          ")]):_vm._e(),_vm._v(" "),(
              !room.current_user_booked &&
              room.participant_limit &&
              room.participant_count === room.participant_limit
            )?[_c('p',{staticClass:"video-conferencing__session-full"},[_vm._v("Full!")])]:_vm._e()],2)])}),0)]):_vm._e(),_vm._v(" "),(_vm.shouldShowIndividualSessionModal == true)?_c('individual-session-modal',{attrs:{"room":_vm.openedSession,"participant":_vm.participant},on:{"closeIndividualSessionModal":function($event){return _vm.toggleIndividualSessionModal()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }