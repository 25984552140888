<template>

    <section class="forgotten-password">

        <div class="forgotten-password--nav">
            <logoBar></logoBar>
            <navigationButtons class="video-conferencing__navigation-buttons"></navigationButtons>
        </div>     

        <main id="content"> 
            <header>
                <h1> 
                    <font-awesome-icon icon="fa-solid fa-key" aria-hidden="true" role="presentation"/>
                    Reset Password 
                </h1>
            </header>

            <form class="forgotten-password__form">
                <fieldset class="mt-4">
                    <input type="password" id="new_password" name="new_password" v-model="password" aria-required="true" required :aria-invalid="passwordInputNotSet" >
                    <label for="new_password"> New Password: <span class="required-star"> * </span></label>
                </fieldset>
                <fieldset class="my-4">
                    <input type="password" id="confirm_password" name="confirm_password" v-model="password_confirmation" aria-required="true" required :aria-invalid="confirmPasswordInputNotSet">
                    <label for="confirm_password" > Password confirmation: <span class="required-star"> * </span></label>
                </fieldset>

                <div class="buddies__message--error mb-2" v-if="error" role="alert" aria-live="polite">
                    {{ errorText }}
                </div>

                <button @click.prevent="resetPassword" :aria-busy="[shouldShowLoadingMessage ? true : false ]" aria-live="polite" :aria-role="[shouldShowLoadingMessage ? 'alert': 'button' ]">
                    <template v-if="shouldShowLoadingMessage">
                        {{ loadingText }}
                    </template>
                    <template v-else>
                        Update Password
                    </template> 
                </button>


            </form>
        </main>
    </section>

</template>


<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import user from "../../store/user";

export default {
    name: 'ResetPassword',
    data() {
        return {
            password: '',
            password_confirmation: '',
            token: null,
            error: false,
            shouldShowLoadingMessage: undefined,
            loadingText: '',
            errorText: 'Error',
            passwordInputNotSet: false,
            confirmPasswordInputNotSet: false,
        }
    },
    components: {
        navigationButtons,
        logoBar
    },
    methods: {
        resetPassword() {
            this.error = false;
            this.errorText = 'Error'
            this.shouldShowLoadingMessage = true;
            this.loadingText = "Updating..."
            if(this.password !== '' && this.password_confirmation !== '') {
                user.dispatch("passwordReset", {
                    reset_token: this.$route.params.token,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                }).then((response) => {
                    this.$router.push({ path: '/' });
                }).catch((response) => {
                    this.error = true;
                    this.shouldShowLoadingMessage = false;
                    this.loadingText = ""
                });
            } else if(this.password == '') {
                this.passwordInputNotSet = true;
                this.shouldShowLoadingMessage = false;

                this.loadingText = "";
                document.querySelector('#new_password').focus();
                this.error = true;
                this.errorText = "Please enter your new password"

            } else if(this.password_confirmation == '') {
                this.confirmPasswordInputNotSet = true;
                this.shouldShowLoadingMessage = false;

                this.loadingText = "";
                document.querySelector('#confirm_password').focus();
                this.error = true;
                this.errorText = "Please confirm your new password"
                
            }
    
          
        },
    }
}
</script>

