<template>

    <div 
        class="now-play__single-video"
        :class="{
            'blue-border'       : isPopularVideo,
            'orange-border'     : isLatestVideo,
        }"
        :style="{ backgroundImage: 'url(' + videoBackgroundImage + ')' , backgroundSize: 'cover', backgroundPosition: 'center'}"
        :aria-label="video.thumbnail_alt_text"
        @click="toggleVideoPlayer()"
    >

        <div class="now-play__single-video__title">
            <h3 v-if="video.name.length < 15">  <a :href="video.video" target="_blank"> {{ video.name }} </a> </h3>
            <h3 v-else>  <a :href="video.video" target="_blank"> {{ video.name.slice (0,15) }}... </a> </h3>
        </div>

       <div v-if="shouldShowVideoPlayer">
            <video-player  :video="video" @close="toggleVideoPlayer()"></video-player>
       </div>
    </div>

</template>

<script>
import VideoPlayer from '../VideoPlayer.vue'
export default {
  components: { VideoPlayer },
    name: "SingleVideo",
    props: ['video', 'isPopularVideo', 'isLatestVideo', 'category'],
    data() {
        return {
            shouldShowVideoPlayer: false,
        }
    },

    methods: {
        toggleVideoPlayer() {
            this.shouldShowVideoPlayer = !this.shouldShowVideoPlayer;
        },
        closeVideoPlayer() {
            this.shouldShowVideoPlayer = false;
        },
    },

    computed: {
        videoBackgroundImage() {
        
            if(this.video.result_image) {
                return this.video.result_image
            } else {
                return this.video.thumbnail_url
            }
        }
    }
}
</script>

<style scoped lang="scss">

    .dark-grey-background {
        background:#333333;
    }
    .orange-border {
        border: 2px solid #d37d0d;
    }

    .blue-border {
        border: 2px solid #1e93be;
    }
    
    .green-border {
        border: 2px solid #88c054;
    }

    .orange-background {
        background:  #d37d0d;
    }

    .blue-background {
        background: #1e93be;
    }

    h3 a,
    h3 a:hover,
    h3 a:visited {
        text-decoration: none;
        color: white;
    }


</style>