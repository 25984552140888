<template>
    <div class="video-player__overlay">
        <div class="video-player">

            <div class="video-player__video-header">
                <div class="video-player__video-header--title">
                    <h3> {{ video.name }} </h3>
                </div>
                <div class="video-player__video-header--close">
                   <span aria-label="close video player"> 
                        <p @click="closeVideoPlayer"> Close </p>
                        <font-awesome-icon icon="fas fa-close"  class="close-icon" aria-label="close" role="button"/>
                   </span>
                </div>
            </div>
            <div class="video-player__video-embed">
                <iframe :src="'https://www.youtube-nocookie.com/embed/'+video.yt_id" frameborder="0"></iframe>
            </div>

            <div class="video-player__video-description">
                <p> {{video.description}}</p>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name:'VideoPlayer',
    props: ['video'],
    data() {
        return {

        }
    },
    created() {
        this.incrementVideoViews();
    },
    methods: {
        incrementVideoViews() {
            axios.get('documents/' + this.video.id + '.json', { params: { increment_view: true} }).then((response) => {
            });
        },
        closeVideoPlayer() {
            this.$emit('close', true);
        },

    },
}
</script>

<style lang="scss" scoped>
    .video-player__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);

        z-index: 100;

        display: flex;
        justify-content: center;
        height: 100%;
    }

    .video-player {
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 70%;
        max-height: 750px;
        max-width: 1200px;
        position: relative;
        top: 20px;
        padding: 20px;
        border-radius: 20px;
        overflow-y: scroll;
    }

    .video-player__video-header {
        display: flex;
        justify-content: space-between;

        .video-player__video-header--close {
            width: 20%;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            p {
                margin-bottom: 0;
                margin-right: 10px;
                font-weight: bold;
                cursor: pointer;
            }
            span {
                display: flex;
                align-items: center;
            }

            .close-icon {
                height: 25px;
                width: 25px;
                border-radius: 100%;
                border: 1px solid black;
                cursor: pointer;

                &:hover {
                    background: grey;
                    color: white;
                }
            }
        }
    }

    .video-player__video-embed {
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 450px;
        margin-top: 20px;
        margin-bottom: 20px;
        iframe {
            width: 80%;
         }
    }

    .video-player__video-description {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: center;

        p {
            white-space: pre-line;
            width: 80%
        }
    }

    @media (max-width: 900px) {
        .video-player {
            width: 90%;
        }

        .video-player__video-embed {
            iframe {
                width: 90%;
            }
        }
    }
</style>