<template>
  <div>
    <nav
      class="video-conferencing-room__navigation"
      :class="{
        'logged-in': participant.website_user_id,
        modifiedStylingForHomepage: modifiedStylingForHomepage,
        modifiedStylingForSessions: modifiedStylingForSessions,
      }"
    >
      <section
        v-if="currentWebsiteUser.logged_in && $route.path == '/'"
        class="welcome-message mb-2 ml-2"
      >
        <p>Welcome, {{ currentWebsiteUser.name }}</p>
        <img
          :src="[
            participant.profile_image_url
              ? participant.profile_image_url
              : '/images/video_conferencing/buddies_notification.png',
          ]"
          :alt="'Profile image of' + currentWebsiteUser.name"
        />
      </section>

      <router-link
        v-if="$route.path != '/'"
        :to="'/'"
        class="video-conferencing-room__navigation-button"
        :class="{
          'modified-styling__sessions-button': modifiedStylingForSessions,
        }"
      >
        <img
          src="/images/video_conferencing/home.png"
          alt="house"
          aria-hidden="true"
          role="presentation"
        />
        <p>home</p>
      </router-link>

      <router-link
        v-if="$route.path != '/edit-profile' && currentWebsiteUser.logged_in"
        :to="'/edit-profile'"
        class="video-conferencing-room__navigation-button"
        :class="{
          'modified-styling__sessions-button': modifiedStylingForSessions,
        }"
      >
        <img
          src="/images/video_conferencing/profile.png"
          alt="user"
          aria-hidden="true"
          role="presentation"
        />
        <p>your profile</p>
      </router-link>

      <router-link
        :to="'/buddies'"
        v-if="currentWebsiteUser.logged_in && $route.path != '/buddies'"
        class="
          video-conferencing-room__navigation-button
          component__online-buddies
        "
        :class="{
          'modified-styling__sessions-button': modifiedStylingForSessions,
        }"
      >
        <span
          :class="[
            onlineBuddies.length > 0 ? 'hasOnlineBuddies' : 'noOnlineBuddies',
          ]"
        >
          <img
            src="/images/video_conferencing/buddies.png"
            alt="users"
            aria-hidden="true"
            role="presentation"
          />
          <sub
            v-if="onlineBuddies.length > 0"
            class="component__online-buddies__notification"
            :class="{ modifiedStylingForCafe: modifiedStylingForCafe }"
          >
            {{ onlineBuddies.length }}
          </sub>
        </span>
        <p>your buddies</p>
      </router-link>

      <router-link
        v-if="$route.path != '/enquiries/get-help'"
        to="/enquiries/get-help"
        class="video-conferencing-room__navigation-button"
        :class="{
          'modified-styling__sessions-button': modifiedStylingForSessions,
        }"
      >
        <img
          src="/images/video_conferencing/help.png"
          alt="question mark inside of a bubble"
          aria-hidden="true"
          role="presentation"
        />
        <p>get help</p>
      </router-link>

      <button
        @click="logout()"
        class="
          video-conferencing-room__navigation-button
          extra-navigation-button
        "
        v-if="currentWebsiteUser.logged_in"
      >
        <font-awesome-icon
          icon="fas fa-sign-out"
          aria-hidden="true"
          role="presentation"
        />
        {{ logoutButton.text }}
      </button>

      <router-link
        to="/register"
        class="
          video-conferencing-room__navigation-button
          extra-navigation-button
        "
        v-if="!currentWebsiteUser.logged_in"
      >
        <font-awesome-icon
          icon="fas fa-user"
          aria-hidden="true"
          role="presentation"
        />
        create account
      </router-link>

      <router-link
        to="/login"
        class="
          video-conferencing-room__navigation-button
          extra-navigation-button
        "
        v-if="!currentWebsiteUser.logged_in"
      >
        <font-awesome-icon
          icon="fas fa-sign-in-alt"
          aria-hidden="true"
          role="presentation"
        />
        login
      </router-link>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import user from "../../store/user";

export default {
  name: "navigationButtons",
  props: [
    "modifiedStylingForCafe",
    "modifiedStylingForSessions",
    "modifiedStylingForHomepage",
  ],
  components: {
    // favouriteButton
  },
  data: function () {
    return {
      participant: {
        website_user_id: undefined,
        buddies: [],
        profile_image_url: "",
      },
      onlineBuddies: [],
      logoutButton: { text: "logout" },
    };
  },
  created: function () {
    this.loadCurrentWebsiteUser();
    this.loadBuddies();
  },
  methods: {
    logout() {
      this.logoutButton.text = "Logging Out ...";
      user
        .dispatch("signOut", {})
        .then((response) => {
          if (this.$route.name !== "home") {
            this.$router.push({ path: "/" });
          } else {
            this.$parent.loadCurrentWebsiteUser();
            this.$parent.loadScheduledRooms();
          }
        })
        .catch((response) => {
          // do something?
        });
    },

    loadBuddies() {
      axios.get("/friendships.json").then((response) => {
        this.participant.buddies = response.data.friends;

        this.participant.buddies.forEach((friend) => {
          if (
            friend.currently_in_lobby_or_room &&
            this.isAlreadyOnlineBuddy(friend) == false
          ) {
            this.onlineBuddies.push(friend);
          }
        });
      });
    },

    isAlreadyOnlineBuddy(friend) {
      if (this.onlineBuddies.length > 0) {
        this.onlineBuddies.forEach((buddy) => {
          if (buddy.id === friend.id) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    },
    loadCurrentWebsiteUser() {
      user.dispatch("loadUserFromSession");

      // Currently needed to populate "this.participant" - but could be rewritten?
      // note will likely need to happen in "then" action from previous user.dispatch
      // clause.
      axios.get("/website_users/current_user.json").then((response) => {
        if (
          response.data &&
          response.data.current_website_user &&
          response.data.current_website_user.logged_in
        ) {
          this.participant.website_user_id =
            response.data.current_website_user.id;
          this.participant.profile_image_url =
            response.data.current_website_user.profile_image_url;
        }
      });
    },
    futureAlert() {
      alert("Future work - to be added later");
    },
  },
  computed: {
    isLoggedIn() {
      return user.getters.isAuthenticated; // Is not reliable
    },
    currentWebsiteUser() {
      return user.state.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-conferencing-room__navigation {
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media screen and (min-width: 480px) and (max-width: 535px) {
    flex-wrap: nowrap;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
.video-conferencing-room__navigation-button {
  width: 115px;

  svg {
    width: 70px;
    height: 70px;
    color: #333333;
  }
}

.modifiedStylingForSessions {
  @media (max-width: 1500px) {
    margin-left: 20px;
  }

  @media (max-width: 535px) {
    margin-left: 0;
    flex-wrap: wrap !important;
  }
}
.modified-styling__sessions-button {
  height: 130px;

  // @media (max-width: 1500px) {
  //   height: unset;
  // }
}

.modifiedStylingForHomepage,
.modifiedStylingForSessions {
  @media (min-width: 1500px) {
    flex-wrap: nowrap;
  }

  .video-conferencing-room__navigation-button,
  .modified-styling__sessions-button {
    @media (max-width: 1500px) {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &.component__online-buddies {
      @media (max-width: 1500px) {
        justify-content: flex-start;
      }

      span.hasOnlineBuddies {
        @media (max-width: 1500px) {
          margin: 0;
        }

        img,
        svg {
          @media (max-width: 1500px) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.modifiedStylingForHomepage {
  flex-wrap: wrap;
}

.hasOnlineBuddies
  .component__online-buddies__notification.modifiedStylingForCafe {
  right: 20px;
  bottom: 5px;
}

.new-navigation-buttons__cafe nav {
  justify-content: flex-start !important;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .extra-navigation-button {
    display: flex;
    align-items: center;
    flex-direction: column;

    svg {
      margin-right: 10px;
    }
  }
}

.welcome-message {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  p {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid rgb(72, 7, 120);
    margin-left: 10px;
    padding: 5px;
  }
}
</style>
