<template>

    <div class="individual-session-modal__overlay">
        <section class="individual-session-modal">
            <div class="individual-session-modal__header">

                <div class="individual-session-modal__header--title">
                    <h2>  {{ room.name }} </h2>
                </div>

                <div class="individual-session-modal__header--close-modal" @click="closeModal" role="button">
                    <font-awesome-icon icon="fas fa-times" aria-label="close" role="button"/>
                    <p> Close </p>
                </div>
            </div>

            <div class="individual-session-modal__details">
                <section class="detail-set">
                    <h4>
                        <font-awesome-icon icon="fas fa-calendar-day" aria-hidden="true" role="presentation"/>
                        Session details:
                    </h4>
                    <p> {{ room.descriptive_start_date }} at {{ room.casual_start_time }} </p>
                </section>

                <section class="detail-set">
                    <h4>
                        <font-awesome-icon icon="fas fa-info-circle"  aria-hidden="true" role="presentation"/>
                        Session description:
                    </h4>
                    <p> {{ sessionDescription }} </p>
                </section>

                <section class="detail-set">
                    <h4>
                        <font-awesome-icon icon="fas fa-users" aria-hidden="true" role="presentation" />
                        Session participants:
                    </h4>
                    <p v-show="room.participant_limit == null"> {{ room.participant_count }} participants so far </p>
                    <p v-show="room.participant_limit !== null"> {{ room.participant_count }} / {{ room.participant_limit }} participants </p>
                </section>

                <section class="detail-set">
                    <h4>
                        <font-awesome-icon icon="fas fa-mobile-alt" aria-hidden="true" role="presentation" />
                        Contact details:
                    </h4>
                    <p v-show="room.contact_name"> {{ room.contact_name }} </p>
                    <a :href="'tel:' + room.contact_telephone " v-show=" room.contact_telephone"> {{  room.contact_telephone }} </a>
                    <a :href="'mailto:'+ room.contact_email" v-show=" room.contact_email"> {{  room.contact_email }}</a>
                    <p v-if="hasNoContactDetails"> No contact details available</p>
                </section>
            </div>

            <div class="individual-session-modal__buttons">
                <span class="individual-session-modal__span">
                    <p class="individual-session-modal__non-bookable" v-if="!room.bookable">You must be logged in to book this session</p>
                    <router-link
                    v-if="!room.current_user_booked && (room.participant_limit === null || room.participant_count < room.participant_limit) && room.bookable"
                    :to="'/book/' + room.uuid"
                    tag="button"
                    class="video-conferencing__session-book"
                    >
                        Book
                    </router-link>

                    <button class="video-conferencing__session-cancel" v-if="room.current_user_booked && !room.joinable && !shouldShowCancelBookingWarning" @click="prepareToCancelBooking()">
                        Cancel Booking
                    </button>

                    <button class="video-conferencing__session-cancel" v-if="room.current_user_booked && shouldShowCancelBookingWarning"  @click="cancelBooking(room)">
                        Are you sure?
                    </button>

                    <button class="video-conferencing__session-join" v-if="room.current_user_booked && room.joinable" @click="joinRoom(room)">
                      Join Session
                    </button>

                    <button
                       @click="closeModal"
                        class="video-conferencing__session-more">
                        Close
                    </button>

                    <template v-if="!room.current_user_booked && (room.participant_limit && room.participant_count === room.participant_limit)">
                        <p class="video-conferencing__session-full">
                            Full!
                        </p>
                    </template>
                </span>
            </div>
        </section>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "IndividualSessionModal",
    props: ['room', 'participant'],

    data() {
        return {
            shouldShowCancelBookingWarning: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeIndividualSessionModal')
        },

        prepareToCancelBooking() {
            this.shouldShowCancelBookingWarning = !this.shouldShowCancelBookingWarning
        },

        joinRoom(room) {
          this.$router.push({ path: '/join/' + room.uuid})
        },

        cancelBooking(room) {
            axios.delete("/video_conference/rooms/" + room.uuid + "/participants/" + this.participant.website_user_id + ".json").then((response) => {
                if (response.data.success) {
                room.current_user_booked = false
                room.participant_count -= 1
                } else {
                // Error?
                }
            })
        },
        futureAlert() {
            alert("Future work - to be added later");
        },
    },
    computed: {
        sessionDescription() {
            if(this.room.long_description !== '') {
                return this.room.long_description
            } else if (this.room.short_description !== '') {
                return this.room.short_description
            } else {
                return 'No description available'
            }
        },
        hasNoContactDetails() {
            return (this.room.contact_name == '' && this.room.contact_email == '' && this.room.contact_telephone == "")
        }
    }
}
</script>
