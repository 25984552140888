<template>
    <div class="now-play__video-category-individual-page-wrapper">
        <logoBar></logoBar>
        <div class="now-play__video-category-individual-page">
            <navigationButtons></navigationButtons>
            <loading v-if="!isLoaded"></loading>

            <a @click="$router.go(-1)" class="page-navigation__back">
                <font-awesome-icon icon="fas fa-angle-double-left" aria-hidden="true" role="presentation"/>
                go back
            </a>

            <main class="now-play__video-category-individual-page--list" id="content">

                <div class="now-play__video-category-individual-page--title">
                    <div class="now-play__categories-page--title">
                        <header>
                            <span 
                            
                            class="line"
                            :class=" {
                                'orange-line'                 : category.name.includes('Fab'), 
                                'green-line'                  : category.name.includes('Children') ,
                                'blue-line'                   : category.name.includes('Training'),
                                'dark-line'                   : notCategorised
                            }"
                            ></span>
                            <h1> {{ category.name }} </h1>
                        </header>
                    </div>
                </div>

                <div class="now-play__video-category-individual-page--videos" v-if="videos.length > 0">
                    <single-video 
                        v-for="(video, index) in videos" 
                        :key="index"
                        :video="video"
                        :category="category"
                    ></single-video>
                </div>

                <div v-else class="now-play__no-videos-note">
                    <p> There are currently no videos available</p>
                </div>

            </main>
        </div>
    </div>
</template>


<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import Loading from '../shared/loading.vue';
import SingleVideo from './secondary/SingleVideo.vue';

export default {
    name: 'VideoCategoryPage',
    props: ['category'],

    data() {
        return {
            videos: [],
            documents: [],
            isLoggedIn: false,
            isLoaded: false,
            currentUser: []
        }
    },
    components: {
        logoBar,
        navigationButtons,
        Loading,
        SingleVideo
    },

    created() {
        this.category = this.$route.params.category;
        this.loadCurrentWebsiteUser()
    },
    mounted() {
        this.decideWhatToShow();
    },
    methods: {
        futureAlert() {
            alert('To be added at a later point');
        },
        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.isLoaded   = true;
                    this.currentUser = response.data.current_website_user;
                } else {
                    this.isLoaded   = true;
                }
            })
        }, 

        /// as we're passing categories as a prop trough vue-router, 
        /// this function protects the page from not showing anything if the user
        /// randomly/via shared link visits the page and no categories are passed 
        decideWhatToShow(){
            if(this.category == undefined ) {
                this.$router.push('/now_play/categories')
            } else {
               this.loadCategoryVideos();
            }
        },

        loadCategoryVideos() {
            axios.get('documents.json?page=1&per_page=10&search_document%5Bterm%5D=').then((response) => {

                // get all documents
                this.documents = response.data.documents;

                // sort documents based on category name
                this.videos  = this.documents.filter(document => document.categories.includes(this.category.name));
            });
        }
    },
    computed: {

        // sets a dark coloured line for the title of the category
        notCategorised() {
            return (
                !this.category.name.includes('Fab') && 
                !this.category.name.includes('Children') &&
                !this.category.name.includes('Training')
            )
        }
    }
}
</script>

<style lang="scss" scoped>

    .now-play__video-category-individual-page {
        min-height: 800px;
        margin-top: 10px;

        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }
    .now-play__video-category-individual-page--list {
        display: flex;
        flex-direction: column;
    }

    .now-play__video-category-individual-page--title {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        vertical-align: middle;
        header {
            display: flex;
            align-items: center;
            

            h1 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }

    .now-play__video-category-individual-page--videos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
</style>