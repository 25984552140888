<template>
    <div v-show="shouldShowWebcamUpload" :class="{'registration-form__image-settings--camera-upload my-4 d-flex flex-column': shouldShowWebcamUpload }">

        <label for="videoPreviewContainer"> You are taking a picture using your webcam: </label>

        <div class="video-container-wrapper">
            <div v-show="!isPhotoTaken" id="videoPreviewContainer" class="video-preview-container"></div>

            <div v-show="shouldShowCountdown" class="countdown"> {{ count }} </div>
        </div>

        <button class="video-preview-controls" v-if="!isPhotoTaken" @click="triggerCountdown"> 
            Take Photo
        </button>

        <div v-show="isPhotoTaken" class="camera-upload__snapshot">
            <img ref="avatar_preview" id="snapshot" />
            <div class="camera-upload__snapshot--controls">
                <button @click="retakePhoto">Retake photo</button>
                <button v-if="isPhotoTaken" @click="deleteCameraTakenPhoto">Remove photo</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CameraPreviewWindow',

        props: ['shouldShowWebcamUpload','isPhotoTaken', 'shouldShowCountdown', 'imageHasBeenSaved', 'count', 'currentUser'],

        methods: {
            retakePhoto() {
                this.$emit('retakePhoto');
            },
            setProfileImageAsCameraTakenPhoto () {
                this.$emit('setProfileImageAsCameraTakenPhoto');
            },
            triggerCountdown() {
                this.$emit('triggerCountdown');
            },
            deleteCameraTakenPhoto() {
                this.$emit('resetCurrentUserAvatarURL');
                this.$emit('removeCameraTakenPhoto');
                this.$emit('disableAudioVideoPermissions');
                this.$emit('setCameraPreviewWindow');
            }
        }
    }
</script>

<style scoped lang="scss">

    .video-container-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .video-preview-container {
        display: flex;
        align-self: center;

        height: 204px;
        width: 250px;

        border: 3px solid #0057AE;
        margin: 10px;
    }

    .countdown {
        height: 200px;
        width: 200px;

        color: white;
        font-size: 64px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
    }

    // TAKE PHOTO BUTTON
   button.video-preview-controls {
        background-color: unset;
        border: 1px solid grey;
        border-radius: 4px;
        padding: 5px 15px;
        margin: 10px;
    }
    // SAVED PHOTO BUTTON
    button.saved {
        background-color: unset;
        border: 1px solid grey;
        border-radius: 4px;
        margin: 10px;
        padding: 5px 15px;
        background-color: darken(#74ba51, 10%);
        color: #333333;
    }


    .camera-upload__snapshot {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 250px;
            height: 200px;
            margin-left: 10px;
        }

        &--controls {
            display: flex;
            justify-content: center;

            button {
                background-color: unset;
                border: 1px solid grey;
                border-radius: 4px;
                margin: 10px;
                padding: 5px 15px;
            }

            button:hover:not(button[disabled]) {
                background-color: #74ba51;
                color: #333333;
            }
        }

    }

    @media (max-width: 780px) {
        .registration-form__image-settings--camera-upload {
            align-items: center;
            text-align: center;
        }
    }
</style>