<template>
    <section class="cms-page__outer">
        <logoBar></logoBar>
        <navigation-buttons></navigation-buttons>

        <main class="cms-page" id="pageTop">
            <h1 v-if="page.title" id="content" class="cms-page__page-title"> {{ page.title }} </h1>
                
            <section class="cms-page__missing" v-if="page_not_found == true">
                <p> The page you were looking for appears to have been moved, deleted or does not exist. </p>
                <p> You can go to our 
                    <router-link to="/"> home page </router-link>
                     or 
                     <router-link to="/enquiries/get-help"> get in contact</router-link>
                      with us.
                </p>
            </section>

            <section class="cms-page__found" v-else v-html="page.text">
            </section>

        </main>

    </section>
</template>


<script>
import axios from "axios";
import navigationButtons from '../navigationButtons.vue';
import LogoBar from '../logoBar.vue';

export default {
    name: "CmsPage",

    data() {
        return {
            page: {
                title: '',
                text: ''
            },
            page_not_found: undefined,
        }
    },
    components: {
        navigationButtons,
        LogoBar
    },
    created() {
        this.loadCurrentPage();
    },

    mounted(){
        this.scrollIntoView();
    },

    watch:{
        $route (to, from){
            this.clearData();
            this.loadCurrentPage();
            this.scrollIntoView();
        }
    }, 
    
    methods: {

        // clears data passed trough props
        clearData() {
            this.page.title = '';
            this.page.text  = '';
            this.page_not_found = undefined
        },

        scrollIntoView() {
            let pageTop = document.getElementById('pageTop');

            pageTop.scrollIntoView({behavior: "smooth"});
        },

        loadCurrentPage() {
            let path = this.$route.path;

             axios.get("/fragments" + path).then((response) => {
               if(response.data.title && response.data.html) {
                    this.page.title = response.data.title;
                    this.page.text  = response.data.html;
               }
               else {
                   this.page_not_found = true;
               }
             }).catch((error) => {

                // if page was never created in quantum, request will error
                // this catches the error

                this.page_not_found = true;
             })
        }
    },
}
</script>