<template>
    <section class="cms-page__outer">
        <logoBar></logoBar>
        <navigation-buttons></navigation-buttons>

        <main class="cms-page" id="pageTop">
                
            <section class="cms-page__missing" id="content">
                <p> The page you were looking for appears to have been moved, deleted or does not exist. </p>
                <p> You can go to our 
                    <router-link to="/"> home page </router-link>
                     or 
                     <router-link to="/enquiries/get-help"> get in contact</router-link>
                      with us.
                </p>
            </section>

        </main>

    </section>
</template>


<script>
import navigationButtons from '../navigationButtons.vue';
import LogoBar from '../logoBar.vue';
export default {
    name:"NotFound",
    components: {
        navigationButtons,
        LogoBar
    }
}
</script>