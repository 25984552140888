<template>
    <div div class="buddies-page__outer">
        <logoBar></logoBar>
        <div class="buddies__wrapper">
            <navigationButtons></navigationButtons>
            <main id="content" v-if="!isLoggedIn && isLoaded">
                <div class="buddies__header logged-out">          
                    <div class="buddies__header--title">
                        <h1> 
                            <font-awesome-icon icon="fas fa-user-plus" aria-hidden="true" role="presentation"/>
                            Buddy Requests 
                        </h1>
                        <p>This feature is only available to registered users</p>
                    </div>
                    <div class="buddies__header--triggers">
                        <router-link to="/register" tag="button" class="button blue" > Register </router-link>
                        <router-link to="/login" tag="button" class="button green"> Login </router-link>
                    </div>
                </div>
            </main>

            <main id="content" class="buddies" v-if="isLoggedIn">

                <div class="buddies__message--success" v-show="successMessage !== '' && shouldShowSuccessMessage " role="alert" aria-live="polite">
                    <p> {{ successMessage }} </p>
                    <button class="close-error-message" @click="closeSuccessMessage" aria-label="hide message">
                        x
                    </button>
                </div>

                <div class="buddies__message--error" v-show="errorMessage !== '' && shouldShowErrorMessage" role="alert" aria-live="polite">
                    <p> {{ errorMessage }} </p>
                    <button class="close-error-message" @click="closeErrorMessage" aria-label="hide message">
                        x
                    </button>
                </div>

                <div class="buddies__header">          
                    <div class="buddies__header--title">
                        <h1> 
                            <font-awesome-icon icon="fas fa-user-plus" aria-hidden="true" role="presentation"/>
                            Buddy Requests 
                        </h1>
                    </div>
                    <div class="buddies__header--trigerrs" v-show="userAllowsOthersToAddThem">
                        <button v-show="!tryingToAddANewBuddy" class="button blue" @click="toggleAddNewBuddy"> Add a buddy </button>
                    </div>
                </div>

                <section class="buddies__add-new-buddy-section">
                    <div class="buddies__form-wrapper" v-if="tryingToAddANewBuddy">
                        <form class="add-buddy__form">
                            <label for="email"> Please type your buddy's email address:</label>
                            <input type="email" name="email" id="email" v-model="emailToSendInvitationTo" placeholder="e.g sasha@email.com" autocomplete="email" aria-required="true" required>
                            <span class="form-triggers">
                                <button  class="button grey" @click.prevent="toggleAddNewBuddy"> Cancel </button>
                                <button  class="button green" @click.prevent="sendInvitation">create an invite </button>
                            </span>
                        </form>
                    </div>
                </section>

                <section class="buddies__checkbox" :class="{'checked' : userAllowsOthersToAddThem }" v-if="isLoaded">
                    <input name="checkbox" id="checkbox" type="checkbox" v-model="toggleAllowedFriendshipsTickbox" @change="verifyTickboxChange">
                    <label for="checkbox">I want to be able to add buddies and have others add me as one</label>
                </section>

                <section class="buddies__checkbox__verify" v-if="shouldVerifyTickboxChangedStatus">
                <div class="buddies__checkbox__verify--inner">
                        <p> This action will remove your current friendships and stop others from adding you as one</p>
                        <p> Are you sure you want to proceed?</p>
                        
                        <div class="verify--triggers">
                            <button class="button grey" @click="cancelTickboxChangedStatus"> Cancel </button>
                            <button class="button green" @click="proceedWithTickboxChangedStatus"> Yes </button>
                        </div>
                </div>
                </section>

                <div class="buddies__list" v-show="userAllowsOthersToAddThem">

                    <loading v-if="!isLoaded" :customText="'Loading buddies...'"></loading>

                    <section class="buddies__pending-requests">
                        <h3> 
                            <font-awesome-icon icon="fa-solid fa-user-clock" aria-hidden="true" role="presentation"/>
                            Pending requests 
                            <span>
                                 ( {{ pendingBuddies.length }} ) 
                            </span> 
                            </h3>
                        <table v-if="pendingBuddies.length > 0">
                            <th> Name:</th> 
                            <tr v-for="(buddy, index) in pendingBuddies" :key="index">
                                <td> {{ buddy.requestor_name }}</td>
                                <td>                         
                                    <span>
                                        <button class="button green" @click="acceptRequest(buddy.id)"> Accept </button>
                                        <button class="button orange" @click="removeFriend(buddy.id)"> Ignore </button>
                                    </span>
                                </td>                     
                            </tr>
                        </table>
                    </section>
                    <section class="buddies__current-buddies">
                        <h3> 
                           <font-awesome-icon icon="fa-solid fa-user-check" aria-hidden="true" role="presentation"/>
                            My Buddies
                                <span>
                                    ( {{currentBuddies.length}} )
                                </span> 
                            </h3>
                        <table v-if="currentBuddies.length > 0">
                            <th> Name:</th> 
                            <tr v-for="(buddy, index) in currentBuddies" :key="index">
                                <td> {{ buddy.name }}</td>
                                <td> <button class="button orange" @click="removeFriend(buddy.friendship_id)"> Remove</button> </td>
                            </tr>
                        </table>
                    </section>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import Loading from '../shared/loading.vue';

export default {
    name: 'Buddies',
    data() {
        return {
            tryingToAddANewBuddy: false,
            currentBuddies: [],
            pendingBuddies: [],
            emailToSendInvitationTo: '',
            toggleAllowedFriendshipsTickbox: false,
            userAllowsOthersToAddThem: false,
            shouldVerifyTickboxChangedStatus: false,
            successMessage: '',
            errorMessage: '',
            isLoaded: false,
            isLoggedIn: false,
            currentUser: [],
            shouldShowErrorMessage: undefined,
            shouldShowSuccessMessage: undefined
        }
    },
    components: {
        navigationButtons,
        logoBar,
        Loading,
    },
    created() {
        this.loadCurrentWebsiteUser();
        this.getFriendsData();
    },
    methods: {

        closeErrorMessage() {
            this.shouldShowErrorMessage = false;
            this.errorMessage = ''
        },

        closeSuccessMessage() {
            this.shouldShowSuccessMessage = false;
            this.successMessage = ''
        },

        futureAlert() {
            alert('To be added in the future');
        },

        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.isLoaded   = true;
                    this.currentUser = response.data.current_website_user;
                    this.userAllowsOthersToAddThem = response.data.current_website_user.allow_friendships;


                    if(this.userAllowsOthersToAddThem) {
                        this.toggleAllowedFriendshipsTickbox = true;
                    } else {
                        this.toggleAllowedFriendshipsTickbox = false;
                    }
                }
            })
        },

        getFriendsData() {
             axios.get("/friendships.json").then((response) => {
                this.currentBuddies = response.data.friends;
                this.pendingBuddies =  response.data.friendship_requests;
                this.isLoaded       = true;
            })
        },

        toggleAddNewBuddy() {
            this.tryingToAddANewBuddy = !this.tryingToAddANewBuddy;
        },

        verifyTickboxChange() {
            if(!this.toggleAllowedFriendshipsTickbox) {
                // ui trigger
                this.shouldVerifyTickboxChangedStatus = true

            } else if (!this.userAllowsOthersToAddThem){

                // ui trigger
                this.shouldVerifyTickboxChangedStatus = false;

                // record user allows friendships with a call to the api bellow
                axios.put("/website_users/current_user.json", { allow_friendships : 'yes' } ).then((response) => {
                   if(response) {
                       this.loadCurrentWebsiteUser();
                   }
                })
            }
        },

        cancelTickboxChangedStatus() {
            // ui triggers
            this.toggleAllowedFriendshipsTickbox = true;
            this.shouldVerifyTickboxChangedStatus = false;
        },

        proceedWithTickboxChangedStatus() {
            // ui triggers
            this.toggleAllowedFriendshipsTickbox = false;
            this.shouldVerifyTickboxChangedStatus = false;

            // revoke friendships features with a call to the api bellow    
            axios.put("/website_users/current_user.json", { allow_friendships : 'no'}).then((response) => {
                if(response) {
                    this.loadCurrentWebsiteUser();
                    this.getFriendsData();
                }
            })

        },

        sendInvitation() {
           if(this.emailToSendInvitationTo !== '') {

                axios.post("/friendships.json", { email: this.emailToSendInvitationTo} ).then((response) => {

                if(response.data.success == true) {
                    this.emailToSendInvitationTo = '';
                    
                    this.successMessage = 'Request has been sent'
                    this.shouldShowSuccessMessage = true;
                    this.shouldShowErrorMessage = false;
                    this.errorMessage = '';

                } else {
                    this.shouldShowErrorMessage = true
                    this.errorMessage = 'Unable to send a request';
                    this.emailToSendInvitationTo = '';
                }
            })
           }
        },

        acceptRequest(friendship_id) {
            axios.put("/friendships/" + friendship_id + ".json", { approved: true } ).then((response) => {
                
                if(response) {
                    this.getFriendsData();
                }

                //// add success/ error messages below
                if(response.data.success === false || response.status !== 200) {
                     this.errorMessage = 'Something went wrong';
                     this.shouldShowErrorMessage = true
                }

                if(response.data.success === true) {
                    this.successMessage = 'Buddy request has been accepted!';
                    this.shouldShowSuccessMessage = true
                }
            })
            
        },

        /// should work for both pending and current buddies (to delete them)
        removeFriend(friendship_id) {
            axios.delete("/friendships/" + friendship_id + ".json").then((response) => {
               
               if(response) {
                    this.getFriendsData();
                }
                //// add success/ error messages below
                if(response.data.success === false || response.status !== 200) {
                     this.errorMessage = 'Something went wrong';

                    this.shouldShowErrorMessage = true
                }

                if(response.data.success === true) {
                    this.successMessage = 'Buddy has been removed!';

                    this.shouldShowSuccessMessage = true
                }
            })
        }
    }
}
</script>


<style lang="scss" scoped>

    .buddies__wrapper {
        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }
    
</style>
