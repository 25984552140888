
import Vue from 'vue/dist/vue.esm';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight, faClose, faSearch, faUser, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { fa1 } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

// FONT AWESOME
library.add(
    faUser,
    faSearch,
    faChevronLeft,
    faChevronRight,
    faClose,
    faCalendar,
    faUserPlus,
    faUserPen,
    faUserClock,
    faUserCheck,
    faUsers,
    faTimes,
    faInfoCircle,
    faCalendarDay,
    faMobileAlt,
    faCirclePlay,
    fa1,
    faAngleRight,
    faBullhorn,
    faKey,
    faUserTimes,
    faHandsHelping,
    faAngleDoubleLeft,
    faUserMinus,
    faSignInAlt,
    faSignOut,
    faLock,
    faLockOpen,
    ) ;


Vue.component('font-awesome-icon', FontAwesomeIcon);