<template>
  <div class="registration-form__outer">
    <logoBar></logoBar>
    <navigation-buttons
      class="video-conferencing__navigation-buttons"
    ></navigation-buttons>

    <main class="registration-form__wrapper" id="content">
      <!-- FORM TITLE -->
      <div class="registration-form__title text-center">
        <h1>
          <font-awesome-icon
            icon="fa-solid fa-user"
            aria-hidden="true"
            role="presentation"
          />
          Registration Form
        </h1>
        <p class="my-2">To register please fill in your details below</p>
      </div>

      <form>
        <!-- FORM FIELDS -->
        <div class="registration-form__group-fields">
          <!-- PERSONAL DETAILS -->
          <div class="registration-form__personal-details">
            <h2 id="personalDetailsTitle">Personal details:</h2>
            <div
              aria-hidden="true"
              role="presentation"
              class="title-underline"
            ></div>

            <!-- NAME -->
            <fieldset
              class="personal-details__name my-4 d-flex flex-column"
              aria-labelledby="personalDetailsTitle"
            >
              <label for="currentUsername"
                >Please enter your name here: <sup> * </sup>
              </label>
              <input
                type="text"
                id="currentUsername"
                name="currentUsername"
                v-model="currentUser.name"
                placeholder="e.g John Doe"
                aria-required="true"
                required="required"
                autocomplete="name"
                :aria-invalid="nameHasNotBeenSet"
              />
            </fieldset>

            <!-- EMAIL -->
            <fieldset
              class="personal-details__email my-4 d-flex flex-column"
              aria-labelledby="personalDetailsTitle"
            >
              <label for="email">
                Please enter your email here:
                <sup class="required"> * </sup></label
              >
              <input
                type="email"
                id="email"
                name="email"
                v-model="currentUser.email"
                placeholder="e.g john@email.com"
                aria-required="true"
                required="required"
                autocomplete="email"
                :aria-invalid="emailIsInvalid"
              />
            </fieldset>

            <!-- PASSWORD -->
            <fieldset
              class="personal-details__password my-4 d-flex flex-column"
              aria-labelledby="personalDetailsTitle"
            >
              <label for="password">
                Please enter your password here:
                <sup class="required"> * </sup></label
              >
              <input
                type="password"
                id="password"
                name="password"
                v-model="currentUser.password"
                aria-required="true"
                required="required"
                :aria-invalid="passwordFieldIsEmpty"
              />
            </fieldset>

            <!-- CONFIRM PASSWORD -->
            <fieldset
              class="personal-details__password my-4 d-flex flex-column"
              aria-labelledby="personalDetailsTitle"
            >
              <label for="password_confirmation">
                Please enter your password confirmation here:
                <sup class="required"> * </sup></label
              >
              <input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                v-model="currentUser.password_confirmation"
                aria-required="true"
                required="required"
                :aria-invalid="passwordHasNotBeenConfirmed"
              />
              <!-- Matching password validation required -->
            </fieldset>
          </div>
          <!--end of personal details-->

          <!-- CAMERA/PROFILE PIC SETTINGS -->
          <div class="registration-form__image-settings">
            <h2 id="profileImageCollumnTitle">Set a profile image:</h2>
            <div
              aria-hidden="true"
              role="presentation"
              class="title-underline"
            ></div>

            <div class="registration-form__mode-settings">
              <button @click="toggleManualImageUpload" aria-expanded="true">
                Upload an image
              </button>
              <button @click="toggleWebcamImageUpload" aria-expanded="true">
                Use your webcam
              </button>
            </div>

            <!-- IMAGE UPLOAD -->
            <computer-image-upload
              :shouldShowManualUpload="shouldShowManualUpload"
              :currentUser="currentUser"
              @resetCurrentUserAvatarURL="resetCurrentUserAvatarURL"
            ></computer-image-upload>

            <!-- CAM PREVIEW -->
            <camera-preview-window
              :shouldShowWebcamUpload="shouldShowWebcamUpload"
              :isPhotoTaken="isPhotoTaken"
              :shouldShowCountdown="shouldShowCountdown"
              :imageHasBeenSaved="imageHasBeenSaved"
              :count="count"
              @retakePhoto="retakePhoto"
              @setProfileImageAsCameraTakenPhoto="
                setProfileImageAsCameraTakenPhoto
              "
              @triggerCountdown="triggerCountdown"
              @resetCurrentUserAvatarURL="resetCurrentUserAvatarURL"
              @removeCameraTakenPhoto="removeCameraTakenPhoto"
              @disableAudioVideoPermissions="disableAudioVideoPermissions"
              @setCameraPreviewWindow="setCameraPreviewWindow"
            ></camera-preview-window>
          </div>
        </div>
        <!-- end of form-group fields -->

        <section class="registration-form__terms">
          <label for="terms"
            >Please tick this box to confirm you accept our
            <router-link to="/pages/terms-and-disclaimer">
              Terms &amp; Conditions
            </router-link>
          </label>
          <input
            type="checkbox"
            name="terms"
            id="terms"
            v-model="termsAccepted"
            aria-label="accept terms"
            :aria-invalid="termsNotTicked"
          />
        </section>

        <div
          class="buddies__message--error"
          v-if="errorMessage !== '' && shouldShowErrorMessage"
          role="alert"
          aria-live="polite"
        >
          <p>{{ errorMessage }}</p>
          <button
            class="close-error-message"
            @click="closeErrorMessage"
            aria-label="hide message"
          >
            x
          </button>
        </div>

        <div class="registration-form__submit-details">
          <router-link :to="'/'" class="cancel-button"> Cancel </router-link>

          <button
            @click.prevent="validateRetistrationFormDetails()"
            class="register-button"
            :aria-busy="[shouldShowLoadingMessage ? true : false]"
            aria-live="polite"
            :role="[shouldShowLoadingMessage ? 'alert' : 'button']"
            type="submit"
          >
            <template v-if="shouldShowLoadingMessage">
              {{ loadingText }}
            </template>
            <template v-else> Register </template>
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import logoBar from "javascripts/video_conferencing/components/shared/logoBar";
import CameraPreviewWindow from "../registration/CameraPreviewWindow.vue";
import ComputerImageUpload from "../registration/ComputerImageUpload.vue";
import NavigationButtons from "../shared/navigationButtons.vue";
import user from "../../store/user";

export default {
  name: "RegistrationForm",

  props: [],

  components: {
    logoBar,
    CameraPreviewWindow,
    ComputerImageUpload,
    NavigationButtons,
  },

  data() {
    return {
      // TODO: add a store for currentUser?

      currentUser: {
        name: "",
        email: "",
        password: null,
        password_confirmation: null,
        avatar_url: "",
        avatar_data: null,
      },

      preview_window: null,
      videoEnabled: true,
      audioEnabled: true,

      isPhotoTaken: false,
      shouldShowCountdown: false,
      count: 3,

      shouldShowManualUpload: false,
      shouldShowWebcamUpload: false,

      imageHasBeenSaved: false,
      termsAccepted: false,

      errorMessage: "",
      shouldShowErrorMessage: undefined,
      shouldShowLoadingMessage: undefined,
      loadingText: "",
      emailIsInvalid: false,
      nameHasNotBeenSet: false,
      passwordHasNotBeenConfirmed: false,
      passwordFieldIsEmpty: false,
      termsNotTicked: false,
      fromRoom: "",
    };
  },
  created() {
    if (this.$route.params.uuid) {
      this.fromRoom = this.$route.params.uuid;
    }
  },

  methods: {
    closeErrorMessage() {
      this.shouldShowErrorMessage = false;
    },

    toggleManualImageUpload() {
      this.shouldShowManualUpload = !this.shouldShowManualUpload;
      this.shouldShowWebcamUpload = false;

      if (this.preview_window !== null) {
        this.destroyVideoPreviewContainerAndRemovePermissions();
        this.resetCurrentUserAvatarURL();
      }
    },

    toggleWebcamImageUpload() {
      this.shouldShowManualUpload = false;
      this.shouldShowWebcamUpload = !this.shouldShowWebcamUpload;
      this.resetCurrentUserAvatarURL();
      // trigger Vonage API
      this.setCameraPreviewWindow();
    },

    destroyVideoPreviewContainerAndRemovePermissions() {
      // remove videoPreviewContainer
      this.preview_window.destroy();

      // remove audio/video permissoins
      this.disableAudioVideoPermissions();
    },

    resetCurrentUserAvatarURL() {
      this.currentUser.avatar_url = "";
      this.currentUser.avatar_data = null;
    },

    disableAudioVideoPermissions() {
      this.videoEnabled = !this.videoEnabled;
      this.audioEnabled = !this.audioEnabled;
    },

    setProfileImageAsCameraTakenPhoto() {
      this.resetCurrentUserAvatarURL();

      // set camera taken photo as the profile photo for current user
      const img = document.getElementById("snapshot");
      this.currentUser.avatar_url = img.src;

      this.imageHasBeenSaved = true;
    },

    setCameraPreviewWindow() {
      if (this.shouldShowWebcamUpload) {
        this.videoEnabled = true;
        this.audioEnabled = false;

        this.preview_window = new VideoExpress.PreviewPublisher(
          "videoPreviewContainer"
        );

        this.preview_window.previewMedia({
          targetElement: "videoPreviewContainer",
          publisherProperties: {
            resolution: "640x480",
            audioBitrate: 12000,
            // mirror: false,
          },
        });
      } else {
        this.destroyVideoPreviewContainerAndRemovePermissions();
      }
    },

    takePhoto() {
      var video = document
        .getElementById("videoPreviewContainer")
        .getElementsByTagName("video")[0];

      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext("2d").scale(-1, 1);
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth * -1, video.videoHeight);

      canvas.toBlob((blob) => {
        const img = document.getElementById("snapshot");
        img.src = URL.createObjectURL(blob);

        // Store taken photo as currentUser.avatar_data
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.currentUser.avatar_data = reader.result;
        };
      });

      // remove videoPreviewContainer
      this.preview_window.destroy();

      // set to true
      this.isPhotoTaken = !this.isPhotoTaken;

      this.disableAudioVideoPermissions();
    },

    removeCameraTakenPhoto() {
      const img = document.getElementById("snapshot");
      img.src = "";
      this.isPhotoTaken = false;
    },

    retakePhoto() {
      //reset saved image state
      this.imageHasBeenSaved = false;

      //reset counter
      this.count = 3;

      // reset permissions and image URLs
      this.resetCurrentUserAvatarURL();
      this.disableAudioVideoPermissions();

      this.isPhotoTaken = !this.isPhotoTaken;

      const img = document.getElementById("snapshot");
      img.src = "";

      // trigger Vonage API again
      this.setCameraPreviewWindow();
    },

    startCountdown() {
      setTimeout(() => {
        if (this.count > 0) {
          this.count--;
          this.startCountdown();
        } else {
          this.shouldShowCountdown = false;
        }
      }, 1000);

      if (this.count == 0) {
        // wait 1 second then take a picture
        setTimeout(() => {
          this.takePhoto();
        }, 1000);
      }
    },

    triggerCountdown() {
      this.shouldShowCountdown = !this.shouldShowCountdown;
      this.startCountdown();
    },

    validateRetistrationFormDetails() {
      this.emailIsInvalid = false;
      this.nameHasNotBeenSet = false;
      this.passwordHasNotBeenConfirmed = false;
      this.passwordFieldIsEmpty = false;
      this.termsNotTicked = false;

      if (
        this.currentUser.name !== "" &&
        this.currentUser.email !== "" &&
        this.currentUser.password !== null &&
        this.currentUser.password_confirmation !== null &&
        this.currentUser.password == this.currentUser.password_confirmation &&
        this.termsAccepted !== false
      ) {
        this.saveDetails();
      } else if (this.currentUser.name == "") {
        document.querySelector("#currentUsername").focus();
        this.errorMessage = "Please enter your name before continuing";
        this.shouldShowErrorMessage = true;
        this.nameHasNotBeenSet = true;
      } else if (this.currentUser.email == "") {
        document.querySelector("#email").focus();
        this.errorMessage = "Please enter your email before continuing";
        this.shouldShowErrorMessage = true;
        this.emailIsInvalid = true;
      } else if (
        this.currentUser.password == null ||
        this.currentUser.password == ""
      ) {
        // can only have one element focused at the time
        document.querySelector("#password").focus();
        this.errorMessage = "Please enter your password";
        this.shouldShowErrorMessage = true;
        this.passwordFieldIsEmpty = true;
      } else if (
        this.currentUser.password_confirmation == null ||
        this.currentUser.password_confirmation == ""
      ) {
        // can only have one element focused at the time
        document.querySelector("#password_confirmation").focus();
        this.errorMessage = "Please confirm your password";
        this.shouldShowErrorMessage = true;
        this.passwordHasNotBeenConfirmed = true;
      } else if (
        this.currentUser.password !== null &&
        this.currentUser.password_confirmation !== null &&
        this.currentUser.password !== this.currentUser.password_confirmation
      ) {
        // can only have one element focused at the time
        document.querySelector("#password").focus();
        this.errorMessage = "Passwords are not matching. Please try again.";
        this.shouldShowErrorMessage = true;
      } else if (this.termsAccepted !== true) {
        document.querySelector("#terms").focus();
        this.errorMessage = "Please accept terms of use before continuing";
        this.shouldShowErrorMessage = true;
        this.termsNotTicked = true;
      } else {
        this.errorMessage =
          "Please fill out all fields and accept terms of use before continuing";
        this.shouldShowErrorMessage = true;
      }
    },

    saveDetails() {
      let formData = new FormData();
      formData.append("website_user[name]", this.currentUser.name);
      formData.append("website_user[email]", this.currentUser.email);
      formData.append("website_user[password]", this.currentUser.password);
      formData.append(
        "website_user[password_confirmation]",
        this.currentUser.password_confirmation
      );

      if (this.currentUser.avatar_data) {
        formData.append(
          "website_user[single_image_data]",
          this.currentUser.avatar_data
        );
      }

      formData.append("commit", "Register");
      this.shouldShowLoadingMessage = true;
      this.loadingText = "Registering..";

      user
        .dispatch("register", {
          formData,
        })
        .then((response) => {
          if (this.fromRoom) {
            this.$router.push({ path: "/join/" + this.fromRoom });
          } else {
            this.$router.push({ path: "/" });
          }
        })
        .catch((response) => {
          console.log(response);
          if (response.data.errors.email == "has already been taken") {
            this.errorMessage = "Email has already been taken";
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
            this.loadingText = "";
            this.emailIsInvalid = true;
          } else if (
            response.data.errors.password ==
            "is too short (minimum is 6 characters)"
          ) {
            this.errorMessage = "Password is too short. Minimum 6 characters";
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
            this.loadingText = "";
          } else {
            this.errorMessage = "There has been an error. Please try again.";
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
            this.loadingText = "";
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-form__wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

// PAGE TITLE
.registration-form__title {
  margin-top: 60px;
  margin-bottom: 90px;

  h1 {
    color: rgb(72, 7, 120);

    @media (max-width: 500px) {
      font-size: 32px;
    }
  }
}

.registration-form__group-fields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 40px;
  min-height: 400px;

  .registration-form__personal-details,
  .registration-form__image-settings {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;

    input {
      border-radius: 4px;
      border: 1px solid grey;
      text-indent: 10px;

      &:focus,
      &:target {
        outline: 3px dashed #d2014a;
      }
    }

    input[type="file"],
    input:focus[type="file"] {
      outline: none;
      border: unset;
      text-indent: unset;
      background-color: white;
    }

    h2 {
      color: #627487;
      font-size: 20px;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      text-align: center;
    }

    div.title-underline {
      border-top: 1px solid #627487;
      height: 1px;
      width: 50%;
    }

    /// NAME AND EMAIL FIELDS
    .personal-details {
      &__name,
      &__email,
      &__password {
        width: 60%;
      }
    }
  }

  label sup {
    color: #d2014a;
  }

  .registration-form__mode-settings {
    flex-wrap: wrap;
    margin-top: 20px;

    button {
      background-color: unset;
      border: 1px solid grey;
      border-radius: 4px;
      margin: 10px;
      padding: 5px 15px;

      &:hover {
        background: grey;
        color: white;
      }
    }
  }
}

/// REGISTRATION CTAs
.registration-form__submit-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .register-button {
    padding: 12px 35px;
    text-transform: lowercase;
    background-color: #74ba51;
    color: #333333;
    font-size: 22px;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    margin: 10px;

    &:hover {
      background-color: darken(#74ba51, 10%);
    }
  }

  .cancel-button {
    padding: 12px 35px;
    text-transform: lowercase;
    background-color: lightgray;
    color: black;
    font-size: 22px;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    margin: 10px;
  }
}

@media (max-width: 780px) {
  // PAGE TITLE
  .registration-form__title {
    position: relative;
    top: 20px;
  }

  .registration-form__group-fields {
    justify-content: center;
    flex-direction: column;

    .registration-form__personal-details,
    .registration-form__image-settings {
      width: 100%;
      margin-top: 60px;

      .personal-details__name,
      .personal-details__email,
      .personal-details__password {
        width: 80%;
      }
    }
  }

  .registration-form__image-settings .registration-form__mode-settings {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80%;
  }

  /// REGISTRATION CTAs
  .registration-form__submit-details {
    flex-wrap: wrap;
    margin-top: 60px;
  }
}
</style>
