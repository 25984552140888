import axios from "axios";
import user from "./store/user";

const API_URL = ((process.env.NODE_ENV == "production") ? "" : "");

// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = API_URL;

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axios.defaults.withCredentials = true;

// Pass token automatically with all requests
// axios.defaults.headers.common['Authorization'] = user.state.token;

export default {
    loadUser() {
      return axios.get("/website_users/current_user.json");
    },
    login(email, password) {
        return axios.post("/website_users/sign_in.json", {
          website_user: {
            email: email,
            password: password,
          }
      });
    },
    logout() {
        return axios.delete('/website_users/sign_out.json');
    },
    resetPassword(reset_token, password, password_confirmation) {
      return axios.put("/website_users/password.json", {
        website_user: {
          reset_password_token: reset_token,
          password: password,
          password_confirmation: password_confirmation,
      },
      commit: "Change my password"
      });
    },
    registration(formData) {
      return axios.post("/website_users.json",
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    updateProfile(formData) {
      return axios.put("/website_users.json",
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }
}
