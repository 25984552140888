<template>
    <section class="get-help-page">
        <logoBar></logoBar>
        <navigationButtons class="video-conferencing__navigation-buttons"></navigationButtons>
        
        <section class="get-help-page__content">

            <header>
                <h1> 
                    <font-awesome-icon icon="fas fa-hands-helping" aria-hidden="true" role="presentation" />
                    Get help
                </h1>
                <p> If you have any questions about our services, or any feedback about this website then please get in touch either by filling out the form below or contacting us via <a href="mailto:hartlepoolnow@hartlepool.gov.uk">hartlepoolnow@hartlepool.gov.uk</a> or <a href="tel:01429523652">01429 523652</a>.</p>
            </header>

            <form>

                <fieldset>
                    <input type="text" required aria-required="true" id="name" autocomplete="name" placeholder="e.g Sasha M" v-model="name" :aria-invalid="nameIsMissing">
                    <label for="name">  Your Name (required) <span class="required-star"> * </span> </label>
                </fieldset>

                <fieldset>
                    <input type="email" name="email" required aria-required="true" id="email" autocomplete="email" placeholder="e.g sasha@gmail.com" v-model="email" :aria-invalid="emailIsMissing">
                    <label for="email">  Email (required) <span class="required-star"> * </span></label>
                </fieldset>

                <fieldset>
                    <input type="tel" name="telephone" autocomplete="tel" aria-required="false" id="telephone" placeholder="e.g 01234 5678" v-model="tel">
                    <label for="telephone">  Telephone (not required) </label>
                </fieldset>

                <fieldset>
                    <textarea name="message" rows="4" cols="50" required aria-required="true" id="message" placeholder="your message" v-model="message" :aria-invalid="messageIsMissing"></textarea>
                    <label for="message">  Your Message (required) <span class="required-star"> * </span> </label>
                </fieldset>

                <div class="buddies__message--error" v-if="validationMessage !== '' && shouldShowValidationMessage " role="alert" aria-live="polite">
                    <p> {{ validationMessage }} </p>
                    <button class="close-error-message" @click="closeValidationMessage" aria-label="hide message">
                    x
                    </button>
                </div>

                <div class="buddies__message--error" v-if="errorMessage !== '' && shouldShowErrorMessage" role="alert" aria-live="polite">
                    <p> {{ errorMessage }} </p>
                    <button class="close-error-message" @click="closeErrorMessage" aria-label="hide message">
                    x
                    </button>
                </div>

                <div class="buddies__message--success" v-if="successMessage !== '' && shouldShowSuccessMessage" role="alert" aria-live="polite">
                    <p> {{ successMessage }} </p>
                    <button class="close-error-message" @click="closeSuccessMessage" aria-label="hide message">
                    x
                    </button>
                </div>


                <button class="button green" @click.prevent="send"> submit </button>

            </form>
        </section>
    </section>
</template>


<script>
import axios from 'axios';
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
export default {
    name: 'getHelp',
    data() {
        return {
            email: '',
            tel: '',
            message: '',
            name: '',
            validationMessage: '',
            successMessage: '',
            errorMessage: '',
            shouldShowErrorMessage: undefined,
            shouldShowSuccessMessage: undefined,
            shouldShowValidationMessage: undefined,
            nameIsMissing: false,
            emailIsMissing: false,
            messageIsMissing: false,

        }
    },
    components: {
        logoBar,
        navigationButtons
    },
    methods: {
        closeValidationMessage() {
            this.shouldShowValidationMessage = false;
            this.validationMessage = ''
        },
        closeErrorMessage() {
            this.shouldShowErrorMessage = false;
            this.errorMessage = ''
        },
        closeSuccessMessage() {
            this.shouldShowSuccessMessage = false;
            this.successMessage = ''
        },
        send() {
            this.nameIsMissing = false;
            this.emailIsMissing = false;
            this.messageIsMissing = false;

            let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

            if(
                this.name !== '' &&
                this.email !== '' &&
                this.email.match(regex) &&
                this.message !== ''
            ) {

                axios.post('/enquiries.json', {

                    enquiry: {

                        name: this.name,

                        email: this.email,

                        telephone: this.tel,

                        message: this.message

                    }

                }).then((response) => {

                    if(response.status == 200) {
                        this.clearFormData();
                        this.successMessage = "Sent!"
                        this.shouldShowSuccessMessage = true;
                        this.shouldShowValidationMessage = false;
                        this.shouldShowErrorMessage = false;

                        this.validationMessage = '';
                        this.errorMessage = ''

                    } else {
                        this.errorMessage = "We were unable to send the message. Please try again"
                        this.shouldShowErrorMessage = true
                    }
                })

            } else if(this.name == '') {
                document.querySelector('#name').focus();
                this.validationMessage = 'Please enter your name before continuing'
                this.shouldShowValidationMessage = true
                this.nameIsMissing = true;
            }

            else if(this.email == '') {
                document.querySelector('#email').focus();
                this.validationMessage = 'Please enter your email before continuing'
                this.shouldShowValidationMessage = true;
                this.emailIsMissing = true;
            }

            else if(this.message == '') {
                document.querySelector('#message').focus();
                this.validationMessage = 'Please enter your message before continuing'
                this.shouldShowValidationMessage = true;
                this.messageIsMissing = true;
            }
            
            
            else if (!this.email.match(regex)) {
                document.querySelector('#email').focus();
                this.validationMessage = 'Please enter a valid email address'
                this.shouldShowValidationMessage = true;
                this.emailIsMissing = true;
            }
            
            else {
                this.validationMessage = 'Please fill out the required fields'
                this.shouldShowValidationMessage = true
            }
        },

        clearFormData() {
            this.name = '';
            this.email = '';
            this.tel = '';
            this.message = '';
        }
    }
}
</script>

<style scoped>
    .button.green {
        color:#333333;
    }
</style>