<template>
  <section class="edit-profile-wrapper">
    <logoBar></logoBar>
    <navigation-buttons
      class="video-conferencing__navigation-buttons"
    ></navigation-buttons>
    <loading v-if="!isLoaded" :customText="'Loading your profile..'"></loading>

    <main id="content" v-if="!isLoggedIn && isLoaded">
      <section class="edit-profile__header logged-out">
        <header class="edit-profile__header--title">
          <h1>
            <font-awesome-icon
              icon="fa-solid fa-user-pen"
              aria-hidden="true"
              role="presentation"
            />
            Edit your profile
          </h1>
          <p>This feature is only available to registered users</p>
        </header>
        <div class="edit-profile__header--triggers">
          <router-link to="/register" tag="button" class="button blue">
            Register
          </router-link>
          <router-link to="/login" tag="button" class="button green">
            Login
          </router-link>
        </div>
      </section>
    </main>

    <main id="content" class="edit-profile" v-if="isLoggedIn">
      <header class="edit-profile__header">
        <h1>
          <font-awesome-icon
            icon="fa-solid fa-user-pen"
            aria-hidden="true"
            role="presentation"
          />
          Edit your profile
        </h1>
        <p>
          Setting up profile information helps your buddies to connect with the
          right person
        </p>
      </header>

      <form>
        <section class="edit-profile__details">
          <section class="edit-profile__details--personal-details">
            <section class="edit-profile__section-title">
              <h2>Personal Details:</h2>
              <div
                aria-hidden="true"
                role="presentation"
                class="title-underline"
              ></div>
            </section>

            <fieldset class="edit-profile__details__name">
              <input
                type="text"
                name="name"
                autocomplete="name"
                id="name"
                v-model="currentUser.name"
                :aria-invalid="nameHasNotBeenSet"
              />
              <label for="name">Your name:</label>
            </fieldset>

            <fieldset class="edit-profile__details__email">
              <input
                type="email"
                name="email"
                id="email"
                disabled
                v-model="currentUser.email"
              />
              <label for="email"> Your Email:</label>
            </fieldset>

            <section
              class="edit-profile__details__password"
              :aria-expanded="shouldShowChangePasswordFields"
            >
              <fieldset class="edit-profile__password-field_wrapper">
                <legend>
                  To make changes to your account, please confirm your current
                  password
                </legend>

                <fieldset class="edit-profile__current-password-field">
                  <input
                    type="password"
                    id="currentpassword"
                    name="currentpassword"
                    v-model="currentUser.current_password"
                    :aria-invalid="passwordFieldIsEmpty"
                  />
                  <label for="currentpassword"> Current Password </label>
                </fieldset>
              </fieldset>

              <button
                @click="showPasswordChangeFields"
                class="edit-profile__password-change"
              >
                I want to change my password
              </button>

              <fieldset
                v-if="shouldShowChangePasswordFields"
                class="edit-profile__password-field_wrapper"
              >
                <legend>
                  If you wish to change your current password, please enter a
                  new password below
                </legend>

                <fieldset class="edit-profile__new-password-field">
                  <input
                    type="password"
                    name="newpassword"
                    id="newpassword"
                    v-model="currentUser.new_password"
                    :aria-invalid="
                      showPasswordChangeFields && newPasswordIsTooShort
                    "
                  />
                  <label for="newpassword"> New Password</label>
                </fieldset>

                <fieldset class="edit-profile__new-password-field">
                  <input
                    type="password"
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    v-model="currentUser.confirm_new_password"
                    :aria-invalid="
                      showPasswordChangeFields && passwordHasNotBeenConfirmed
                    "
                  />
                  <label for="confirmNewPassword"> Confirm New Password</label>
                </fieldset>
              </fieldset>

              <div
                class="buddies__message--error"
                v-if="passwordWarningText !== '' && shouldShowPasswordWarning"
                role="alert"
                aria-live="polite"
              >
                <p>{{ passwordWarningText }}</p>
                <button
                  class="close-error-message"
                  @click="closePasswordWarning"
                  aria-label="hide message"
                >
                  x
                </button>
              </div>
            </section>
          </section>

          <section class="edit-profile__details--profile-image-settings">
            <section class="edit-profile__section-title">
              <h2>Set a profile image:</h2>
              <div
                aria-hidden="true"
                role="presentation"
                class="title-underline"
              ></div>
            </section>

            <section
              class="edit-profile__current-profile-image"
              v-if="currentUser.profile_image_url"
            >
              <p>Your current profile image:</p>
              <img :src="currentUser.profile_image_url" />
              <div class="edit-profile__details__profile-picture--controls">
                <button @click="toggleWarningForDeletingImage()" class="delete">
                  Delete Image
                </button>
              </div>

              <div
                class="edit-profile__delete-image"
                v-show="shouldShowDeleteImageWarning"
              >
                <p>
                  Are you sure you want to
                  <strong>delete</strong> your current profile image?
                </p>

                <span>
                  <button
                    class="cancel"
                    @click="toggleWarningForDeletingImage()"
                  >
                    Cancel
                  </button>
                  <button class="delete" @click="deleteImage()">
                    Delete image
                  </button>
                </span>
              </div>
            </section>

            <fieldset class="edit-profile__details__profile-picture">
              <section class="edit-profile__details__profile-picture--saved">
                <label for="picture">
                  <template
                    v-if="
                      currentUser.avatar_url == '' &&
                      !currentUser.profile_image_url
                    "
                  >
                    Set
                  </template>
                  <template
                    v-if="
                      currentUser.avatar_url == '' &&
                      currentUser.profile_image_url
                    "
                  >
                    Re-set
                  </template>
                  {{ currentUser.avatar_url == "" ? "y" : "Y" }}our
                  {{
                    currentUser.avatar_url !== "" &&
                    currentUser.profile_image_url
                      ? "new"
                      : ""
                  }}
                  profile image
                </label>
              </section>

              <section class="edit-profile__details__profile-picture--controls">
                <button
                  @click="toggleManualImageUpload()"
                  :class="{ active: isUsingFileUpload }"
                >
                  Upload a file
                </button>
                <button
                  @click="toggleWebcamImageUpload()"
                  :class="{ active: isUsingCamera }"
                >
                  Use your camera
                </button>
              </section>

              <section class="edit-profile__uploads">
                <!-- IMAGE UPLOAD -->
                <computer-image-upload
                  :shouldShowManualUpload="shouldShowManualUpload"
                  :currentUser="currentUser"
                  @resetCurrentUserAvatarURL="resetCurrentUserAvatarURL"
                ></computer-image-upload>

                <!-- CAM PREVIEW -->
                <camera-preview-window
                  :shouldShowWebcamUpload="shouldShowWebcamUpload"
                  :isPhotoTaken="isPhotoTaken"
                  :shouldShowCountdown="shouldShowCountdown"
                  :imageHasBeenSaved="imageHasBeenSaved"
                  :count="count"
                  @retakePhoto="retakePhoto"
                  @setProfileImageAsCameraTakenPhoto="
                    setProfileImageAsCameraTakenPhoto
                  "
                  @triggerCountdown="triggerCountdown"
                  @resetCurrentUserAvatarURL="resetCurrentUserAvatarURL"
                  @removeCameraTakenPhoto="removeCameraTakenPhoto"
                  @disableAudioVideoPermissions="disableAudioVideoPermissions"
                  @setCameraPreviewWindow="setCameraPreviewWindow"
                ></camera-preview-window>
              </section>
            </fieldset>
          </section>
        </section>

        <div
          class="buddies__message--error"
          v-if="errorMessage !== '' && shouldShowErrorMessage"
          role="alert"
          aria-live="polite"
        >
          <p>{{ errorMessage }}</p>
          <button
            class="close-error-message"
            @click="closeErrorMessage"
            aria-label="hide message"
          >
            x
          </button>
        </div>

        <fieldset class="edit-profile__save-details" v-show="isLoggedIn">
          <button
            @click.prevent="validateEditProfileFields()"
            class="save"
            :aria-busy="[shouldShowLoadingMessage ? true : false]"
            aria-live="polite"
            type="submit"
          >
            <template v-if="loadingText !== '' && shouldShowLoadingMessage">
              {{ loadingText }}
            </template>
            <template v-else> Save </template>
          </button>
        </fieldset>
      </form>
    </main>
  </section>
</template>

<script>
import navigationButtons from "../shared/navigationButtons.vue";
import logoBar from "javascripts/video_conferencing/components/shared/logoBar";
import CameraPreviewWindow from "../registration/CameraPreviewWindow.vue";
import ComputerImageUpload from "../registration/ComputerImageUpload.vue";
import axios from "axios";
import Loading from "../shared/loading.vue";
import user from "../../store/user";

export default {
  name: "EditProfile",
  components: {
    navigationButtons,
    logoBar,
    CameraPreviewWindow,
    ComputerImageUpload,
    Loading,
  },
  data() {
    return {
      shouldShowWebcamUpload: false,
      shouldShowManualUpload: false,

      currentUser: {
        name: "",
        email: "",
        avatar_url: "",
        avatar_data: null,
        website_user_id: "",
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      },

      imageHasBeenSaved: false,
      isPhotoTaken: false,
      shouldShowCountdown: false,
      count: 3,

      preview_window: null,
      videoEnabled: true,
      audioEnabled: true,
      shouldShowDeleteImageWarning: false,
      deleteCurrentImage: false,
      isUsingCamera: false,
      isUsingFileUpload: false,
      shouldShowChangePasswordFields: false,
      isLoaded: false,
      errorMessage: "",
      shouldShowErrorMessage: undefined,
      shouldShowPasswordWarning: undefined,
      shouldShowLoadingMessage: undefined,
      loadingText: "",
      nameHasNotBeenSet: false,
      passwordHasNotBeenConfirmed: false,
      newPasswordIsTooShort: false,
      passwordFieldIsEmpty: false,
    };
  },

  created() {
    this.loadCurrentWebsiteUser();
  },
  methods: {
    closeErrorMessage() {
      this.shouldShowErrorMessage = false;
    },

    closePasswordWarning() {
      this.shouldShowPasswordWarning = false;
    },

    loadCurrentWebsiteUser() {
      axios.get("/website_users/current_user.json").then((response) => {
        if (response.data && response.data.current_website_user) {
          this.currentUser.website_user_id =
            response.data.current_website_user.id;
          this.currentUser.name = response.data.current_website_user.name;
          this.currentUser.email = response.data.current_website_user.email;
          this.currentUser.profile_image_url =
            response.data.current_website_user.profile_image_url;
          this.isLoaded = true;
        } else {
          this.isLoaded = true;
        }
      });
    },

    showPasswordChangeFields() {
      this.shouldShowChangePasswordFields =
        !this.shouldShowChangePasswordFields;
    },

    validateEditProfileFields() {
      this.nameHasNotBeenSet = false;
      this.passwordHasNotBeenConfirmed = false;
      this.newPasswordIsTooShort = false;
      this.passwordFieldIsEmpty = false;

      // current password is set - to save details
      if (
        this.currentUser.name !== "" &&
        this.shouldShowChangePasswordFields == false &&
        this.currentUser.current_password !== ""
      ) {
        this.saveDetails();
        this.clearPasswordFields();
      }

      // current password and new password are set - to change password
      else if (
        this.shouldShowChangePasswordFields == true &&
        this.currentUser.new_password !== "" &&
        this.currentUser.confirm_new_password !== "" &&
        this.currentUser.new_password ==
          this.currentUser.confirm_new_password &&
        this.currentUser.current_password !== ""
      ) {
        this.saveDetails();
        this.clearPasswordFields();
      } else if (this.currentUser.name == "") {
        this.errorMessage = "Please enter your name before continuing";
        document.querySelector("#name").focus();
        this.shouldShowErrorMessage = true;
        this.nameHasNotBeenSet = true;
      }

      // current password is not entered - to change password
      else if (
        this.shouldShowChangePasswordFields == true &&
        this.currentUser.new_password !== "" &&
        this.currentUser.confirm_new_password !== "" &&
        this.currentUser.new_password ==
          this.currentUser.confirm_new_password &&
        this.currentUser.current_password == ""
      ) {
        this.errorMessage = "Please enter your current password";
        document.querySelector("#currentpassword").focus();
        this.shouldShowErrorMessage = true;
        this.passwordFieldIsEmpty = true;
      }
      // new password is not matching - to change password
      else if (
        this.shouldShowChangePasswordFields == true &&
        this.currentUser.new_password !== this.currentUser.confirm_new_password
      ) {
        this.errorMessage = "Passwords are not matching. Please try again.";
        document.querySelector("#newpassword").focus();

        this.shouldShowErrorMessage = true;
        this.passwordHasNotBeenConfirmed = true;
      } else if (this.currentUser.current_password == "") {
        this.errorMessage = "Please enter your current password";
        document.querySelector("#currentpassword").focus();
        this.passwordFieldIsEmpty = true;

        this.shouldShowErrorMessage = true;
      } else if (
        this.shouldShowChangePasswordFields == true &&
        this.currentUser.current_password !== "" &&
        this.currentUser.new_password == "" &&
        this.currentUser.confirm_new_password == ""
      ) {
        this.shouldShowChangePasswordFields = false;
        this.saveDetails();
        this.clearPasswordFields();
      }
    },

    saveDetails() {
      this.errorMessage = "";
      let formData = new FormData();
      formData.append("website_user[name]", this.currentUser.name);
      formData.append("website_user[email]", this.currentUser.email);
      if (this.new_password !== "" && this.confirm_new_password !== "") {
        formData.append(
          "website_user[password]",
          this.currentUser.new_password
        );
        formData.append(
          "website_user[password_confirmation]",
          this.currentUser.confirm_new_password
        );
        this.loadingText = "saving... ";
        this.shouldShowLoadingMessage = true;
      }
      formData.append(
        "website_user[current_password]",
        this.currentUser.current_password
      );

      if (this.currentUser.avatar_data) {
        formData.append(
          "website_user[single_image_data]",
          this.currentUser.avatar_data
        );
        this.loadingText = "saving... ";
        this.shouldShowLoadingMessage = true;
      } else if (this.deleteCurrentImage) {
        formData.append("website_user[single_image_delete]", "delete");
        this.loadingText = "saving... ";
        this.shouldShowLoadingMessage = true;
      }

      formData.append("commit", "Update");

      user
        .dispatch("update", {
          formData,
        })
        .then((response) => {
          this.shouldShowLoadingMessage = false;
          this.loadingText = "";
          this.$router.push({ path: "/" });
        })
        .catch((response) => {
          if (response.data.errors.current_password == "is invalid") {
            this.errorMessage =
              "Current password is invalid. Please try again.";
            document.querySelector("#currentpassword").focus();
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
          } else if (
            response.data.errors.password ==
            "is too short (minimum is 6 characters)"
          ) {
            this.errorMessage =
              "New password is too short. Minimum 6 characters";
            document.querySelector("#newpassword").focus();
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
            this.newPasswordIsTooShort = true;
          } else {
            this.errorMessage = "There has been an error. Please try again.";
            this.shouldShowErrorMessage = true;
            this.shouldShowLoadingMessage = false;
          }
        });
    },

    clearPasswordFields() {
      if (this.shouldShowChangePasswordFields) {
        this.currentUser.current_password = "";
        this.currentUser.new_password = "";
        this.currentUser.confirm_new_password = "";
      } else {
        this.currentUser.current_password = "";
      }
    },

    deleteImage() {
      this.deleteCurrentImage = true;
      this.shouldShowDeleteImageWarning = false;
      this.currentUser.profile_image_url = null;
    },

    removeCameraTakenPhoto() {
      const img = document.getElementById("snapshot");
      img.src = "";
      this.isPhotoTaken = false;
    },

    toggleWarningForDeletingImage() {
      this.shouldShowDeleteImageWarning = !this.shouldShowDeleteImageWarning;
    },

    toggleManualImageUpload() {
      this.shouldShowManualUpload = !this.shouldShowManualUpload;
      this.shouldShowWebcamUpload = false;

      this.isUsingCamera = false;
      this.isUsingFileUpload = true;

      if (this.preview_window !== null) {
        this.destroyVideoPreviewContainerAndRemovePermissions();
        this.resetCurrentUserAvatarURL();
      }
    },

    toggleWebcamImageUpload() {
      this.shouldShowManualUpload = false;
      this.shouldShowWebcamUpload = !this.shouldShowWebcamUpload;
      this.isUsingCamera = true;
      this.isUsingFileUpload = false;

      // trigger Vonage API
      this.setCameraPreviewWindow();
    },

    destroyVideoPreviewContainerAndRemovePermissions() {
      // remove videoPreviewContainer
      this.preview_window.destroy();

      // remove audio/video permissoins
      this.disableAudioVideoPermissions();
    },

    resetCurrentUserAvatarURL() {
      this.currentUser.avatar_url = "";
      this.currentUser.avatar_data = null;
    },

    disableAudioVideoPermissions() {
      this.videoEnabled = !this.videoEnabled;
      this.audioEnabled = !this.audioEnabled;
    },

    setProfileImageAsCameraTakenPhoto() {
      this.resetCurrentUserAvatarURL();

      // set camera taken photo as the profile photo for current user
      const img = document.getElementById("snapshot");
      this.currentUser.avatar_url = img.src;

      this.imageHasBeenSaved = true;
    },

    setCameraPreviewWindow() {
      if (this.shouldShowWebcamUpload) {
        this.videoEnabled = true;
        this.audioEnabled = false;

        this.preview_window = new VideoExpress.PreviewPublisher(
          "videoPreviewContainer"
        );

        this.preview_window.previewMedia({
          targetElement: "videoPreviewContainer",
          publisherProperties: {
            resolution: "640x480",
            audioBitrate: 12000,
            // mirror: false,
          },
        });
      } else {
        this.destroyVideoPreviewContainerAndRemovePermissions();
      }
    },

    takePhoto() {
      var video = document
        .getElementById("videoPreviewContainer")
        .getElementsByTagName("video")[0];

      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext("2d").scale(-1, 1);
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth * -1, video.videoHeight);

      canvas.toBlob((blob) => {
        const img = document.getElementById("snapshot");

        img.src = URL.createObjectURL(blob);

        // Store taken photo as currentUser.avatar_data
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.currentUser.avatar_data = reader.result;
        };
      });

      // remove videoPreviewContainer
      this.preview_window.destroy();

      // set to true
      this.isPhotoTaken = !this.isPhotoTaken;

      this.disableAudioVideoPermissions();
    },

    retakePhoto() {
      //reset saved image state
      this.imageHasBeenSaved = false;

      //reset counter
      this.count = 3;

      // reset permissions and image URLs
      this.resetCurrentUserAvatarURL();
      this.disableAudioVideoPermissions();

      this.isPhotoTaken = !this.isPhotoTaken;

      const img = document.getElementById("snapshot");
      img.src = "";

      // trigger Vonage API again
      this.setCameraPreviewWindow();
    },

    startCountdown() {
      setTimeout(() => {
        if (this.count > 0) {
          this.count--;
          this.startCountdown();
        } else {
          this.shouldShowCountdown = false;
        }
      }, 1000);

      if (this.count == 0) {
        // wait 1 second then take a picture
        setTimeout(() => {
          this.takePhoto();
        }, 1000);
      }
    },

    triggerCountdown() {
      this.shouldShowCountdown = !this.shouldShowCountdown;
      this.startCountdown();
    },
  },
  computed: {
    isLoggedIn() {
      return this.currentUser.website_user_id;
    },
    passwordWarningText() {
      if (
        this.currentUser.new_password !== "" &&
        this.currentUser.new_password.length < 7
      ) {
        this.shouldShowPasswordWarning = true;
        return "Password is too short";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
// fixes :focus for labels and inputs
fieldset.edit-profile__details__name,
fieldset.edit-profile__details__email,
fieldset.edit-profile__current-password-field,
fieldset.edit-profile__confirm-password-field,
fieldset.edit-profile__new-password-field {
  display: flex;
  flex-direction: column-reverse !important;
}

.edit-profile__section-title {
  margin-bottom: 30px;

  h2 {
    color: #627487;
    font-size: 1.25rem;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
  }

  div.title-underline {
    border-top: 1px solid #627487;
    height: 1px;
    width: 100%;
  }
}

.edit-profile__current-profile-image {
  img {
    max-width: 244px;
  }
}
</style>
