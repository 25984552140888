<template>
  <div class="video-conferencing-room">
    <logoBar></logoBar>

    <main :id="[!room.joinable ? 'content' : '']" class="video-conferencing-room__not-ready" v-show="!room.joinable">
        <div class="video-conferencing-room__not-ready__content" v-if="room.name && room.cancelled">
          <p> {{ room.name }} session has been cancelled </p>
          <router-link to="/" tag="button" class="button green"> Return to homepage </router-link>
        </div>

        <div class="video-conferencing-room__not-ready__content" v-else-if="room.name && room.scheduled_ended == false">
          <p> {{ room.name }} is scheduled to start at <span> {{ room.casual_start_time }} </span> on <span> {{ room.descriptive_start_date }} </span>. </p>
          <p v-if="current_user_isScheduledAdmin"> As an administrator you will be able to join the session from <span> 25 minutes </span> before the scheduled start time.</p>
          <p v-else> The session will be available to join from <span> 15 minutes </span> before the scheduled start time. </p>
          <router-link to="/" tag="button" class="button green"> Return to homepage </router-link>
        </div>

        <div class="video-conferencing-room__not-ready__content" v-else-if="room.name && room.scheduled_ended == true">
          <p> {{ room.name }} session has ended </p>
          <router-link to="/" tag="button" class="button green"> Return to homepage </router-link>
        </div>
    </main>

    <main :id="[room.joinable ? 'content' : '']" class="video-conferencing-room__not-ready" v-show="room.joinable && room.one_to_one == true && !participant.website_user_id">
      <div class="video-conferencing-room__not-ready__content">
        <p> {{ room.name }} session is a one-to-one session. You must be logged in to join a one-to-one session. You can log in or register using the buttons below if you do not yet have an account.</p>
        <div class="login-notice__ctas">
          <button role="link" @click="toLoginWithRoomUUID()" tag="button" class="button green">Login</button>
          <button role="link" @click="toRegisterWithRoomUUID()"  tag="button" class="button blue">Register</button>
        </div>
        <router-link to="/" tag="button" class="button green"> Return to homepage</router-link>
      </div>
    </main>

    <main :id="[room.joinable ? 'content' : '']" class="video-conferencing-room__not-ready" v-show="room.joinable && room.one_to_one == true && room.available_to_join == false">
      <div class="video-conferencing-room__not-ready__content">
          <p> {{ room.name ? room.name : 'This' }} session is a one-to-one session, and is currently occupied. The page will automatically update when the professional(s) are available again.</p>
          <router-link to="/" tag="button" class="button green"> Return to homepage if you do not wish to wait</router-link>
        </div>
    </main>

    <main :id="[room.joinable ? 'content' : '']" class="video-conferencing-room__precall" v-show="room.joinable && (room.one_to_one == false || room.available_to_join)">
      <div class="video-conferencing-room__precall-main" v-show="callMode == 'precall'">
        <h2 v-if="room.one_to_one == true && !current_user_isAdmin" class="video-conferencing-room__sub-heading">The professional is now available to chat.</h2>
          <h2 v-if="room.one_to_one == true && current_user_isAdmin" class="video-conferencing-room__sub-heading">This is a one-to-one chat. Remember to open and close the room as necessary.</h2>
        <h2 class="video-conferencing-room__sub-heading">You are just three steps away from joining your chat</h2>

        <div class="video-conferencing-room__settings">
          <h2 class="video-conferencing-room__settings-heading">1. Your camera settings</h2>

          <div class="video-conferencing-room__settings--text-container">
            <p class="video-conferencing-room__settings-text">
              <span aria-hidden="true">Your camera is currently</span>
              <span class="sr-only">Your camera is currently {{ videoEnabled ? 'on': 'off'}}</span>
              <button class="video-conferencing-room__button--on" v-if="videoEnabled" @click="disableVideo('room')" aria-label="Turn camera off">On</button>
              <button class="video-conferencing-room__button--off" v-if="!videoEnabled" @click="enableVideo" aria-label="Turn camera on">Off</button>
            </p>

            <p class="video-conferencing-room__settings-text">
              This means people in the room <strong>{{ videoEnabled ? "will" : "will NOT" }}</strong> be able to see you.
            </p>


            <p v-show="videoEnabled" class="video-conferencing-room__settings-text">You can <button class="video-conferencing-room__settings-button" @click="showVideoSettings = !showVideoSettings">change your camera settings</button> before you join the chat.</p>
          </div>

          <div v-show="videoEnabled">
            <div class="video-conferencing-room__current-camera" v-if="showVideoSettings">
              Current Camera:

              <select v-model="currentVideoDevice" @change="updatePreviewVideoDevice">
                <option :value="videoDevice" v-for="videoDevice in allDevices.filter(d => d.kind == 'videoInput')">
                  {{ videoDevice.label }}
                </option>
              </select>
            </div>

            <div id="videoPreviewContainer"></div>
          </div>
        </div>

        <div class="video-conferencing-room__settings">
          <h2 class="video-conferencing-room__settings-heading">2. Your sound settings</h2>

          <div v-if="true">
            <p class="video-conferencing-room__settings-text">
              <span aria-hidden="true">Your microphone is currently</span>
              <span class="sr-only">Your microphone is currently {{ audioEnabled ? 'on': 'off'}}</span>
              <button class="video-conferencing-room__button--on" v-if="audioEnabled" @click="disableAudio('room')" aria-label="Turn microphone off">On</button>
              <button class="video-conferencing-room__button--off" v-if="!audioEnabled" @click="enableAudio" aria-label="Turn microphone on">Off</button>
            </p>

            <p class="video-conferencing-room__settings-text">
              This means people in the room <strong>{{ audioEnabled ? "will" : "will NOT" }}</strong> be able to hear you.
            </p>

            <div v-show="audioEnabled">
              <p class="video-conferencing-room__settings-text">
                <span aria-hidden="true">Audio Level:</span>

                <span class="sr-only">{{ audioLevel > 0 ? 'Audio level indicates the microphone is working' : 'Audio level indicates the microphone may not be working'}}</span>
                <div aria-hidden="true" class="vu-meter" style="border: 1px solid #777; height: 2em; max-width: 500px;">
                  <div :style="'background-color: green; width: ' + audioLevel + '%'">&nbsp;</div>
                </div>
              </p>

              <p class="video-conferencing-room__settings-text">You can <button class="video-conferencing-room__settings-button" @click="showAudioSettings = !showAudioSettings">change your microphone settings</button> before you join the chat.</p>

              <div v-show="showAudioSettings">
                Current Microphone:

                <select v-model="currentAudioDevice" @change="updatePreviewAudioDevice">
                  <option :value="audioDevice" v-for="audioDevice in allDevices.filter(d => d.kind == 'audioInput')">
                    {{ audioDevice.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!participant.website_user_id" class="video-conferencing-room__settings">
          <h2 class="video-conferencing-room__settings-heading">3. Check your details</h2>

          <p class="video-conferencing-room__settings-text">
            As you are not logged in, we will need to know your first name and email address to join the chat.
            This is so people will know who you are.
          </p>

          <div class="video-conferencing-room__settings-form-group">
            <label for="participant-name" class="video-conferencing-room__settings-text--input">
              Please enter your first name here:
            </label>
            <input id="participant-name" class="video-conferencing-room__settings-input" type="text" v-model="participant.name"  placeholder="e.g John Smith" aria-required required autocomplete="name"/>
          </div>

          <div class="video-conferencing-room__settings-form-group">
            <label for="participant-email" class="video-conferencing-room__settings-text--input">
            Please enter your email address here:
            </label>
            <input id="participant-email" class="video-conferencing-room__settings-input" type="email" v-model="participant.email" placeholder="e.g john@email.com" aria-required="true" required autocomplete="email" />

            <router-link to="/pages/terms-and-disclaimer" class="video-conferencing-room__email-info-link">What will you do with my email address?</router-link>
          </div>

          <div class="video-conferencing-room__settings-form-group">
            <label class="video-conferencing-room__settings-checkbox-label" for="terms-confirm">Please confirm you agree to <router-link to="/pages/terms-and-disclaimer">terms of use</router-link> by ticking this box
            <input class="video-conferencing-room__settings-checkbox" type="checkbox" id="terms-confirm" name="terms" value="terms" v-model="participant.terms">
            </label>
          </div>

        </div>

        <div v-if="participant.website_user_id"  class="video-conferencing-room__settings">
          <h2 class="video-conferencing-room__settings-heading">3. Your name</h2>

          <p class="video-conferencing-room__settings-text">
            You will be joining the chat as {{ participant.name }}
          </p>

        </div>

        <div class="error-message" v-if="errorMessage !=='' " role="alert" aria-live="polite">
          <p> {{ errorMessage }}</p>
        </div>

        <button class="video-conferencing-room__join-button" @click="validateJoiningRoom">
           Join Room
        </button>
      </div>

      <div class="video-conferencing-room__precall-sidebar" v-show="callMode == 'precall'">
        <navigationButtons :modifiedStylingForSessions="true"></navigationButtons>
            <div v-if="room.current_participants && room.current_participants.length && typeof room.current_participants[0] === 'string'" class="video-conferencing-room__avatars">
              <p class="video-conferencing-room__avatars-text">You are about to chat with:</p>

              <div class="video-conferencing-room__avatar-container" :key="index" v-for="(participant, index) in room.current_participants">
                <div class="video-conferencing-room__avatar-image-container">
                  <img class="video-conferencing-room__avatar-image" :src="participant_profile_image(room.room_participants, participant)" :alt="participant_profile_image(room.room_participants, participant) === '/images/video_conferencing/buddies_notification.png' ? '' : 'Profile image of ' + participant">
                </div>
                <p class="video-conferencing-room__avatar-text" >{{ participant }}</p>
              </div>
            </div>

            <div v-else-if="!room.current_participants || room.current_participants.length === 0" class="video-conferencing-room__avatars">
              <p class="video-conferencing-room__avatars-text">You will be the first person in the room.</p>
            </div>
            <div v-else>Loading...</div>
          </div>


      </main>

    <!--
            ============
            IN CALL MODE
            ============
    -->


    <div id="video-conferencing-room_incall" :class="participant.website_user_id ? 'logged-in' : ''" v-show="callMode == 'incall'" v-if="room.joinable">
      <main id="content">
        <div class="video-conferencing-room__cafe-status">
          <img class="video-conferencing-room__cafe-status-img" src="/images/video_conferencing/cafe-grey.png" alt="">
          <p v-if="roomCountLoaded" class="video-conferencing-room__cafe-status--text">Cafe Status: {{ roomCount }} other chat{{ roomCount === 1 ? '' : 's'}}</p>
          <p class="video-conferencing-room__cafe-status--text" v-else>Loading cafe status...</p>
        </div>


        <div class="video-conferencing-room__video-buttons">
          <div class="video-conferencing-room__video-button-container">
            <p>If you no longer wish to participate in this call</p>
            <button @click="endCallPreliminary" class="video-conferencing-room__video-button--off">
              <img src="/images/video_conferencing/end-call.png" alt="">
              <span v-if="room.name == 'Adhoc Room'">End Call &amp; give feedback</span>
              <span v-else>End Call &amp; give feedback </span>
            </button>
          </div>
          <div v-if="!audioEnabled" class="video-conferencing-room__video-button-container">
            <p>Your microphone is off so others cannot hear you</p>
            <button @click="enableAudio" class="video-conferencing-room__video-button--on">
              <img src="/images/video_conferencing/mic-on.png" alt="">
              <span>Turn on mic</span>
            </button>
          </div>

          <div v-if="audioEnabled" class="video-conferencing-room__video-button-container">
            <p>Your microphone is on so others can hear you</p>
            <button @click="disableAudio('room')" class="video-conferencing-room__video-button--off">
              <img src="/images/video_conferencing/mic-off.png" alt="">
              <span>Turn off mic</span>
            </button>
          </div>

          <div v-if="!videoEnabled" class="video-conferencing-room__video-button-container">
            <p>Your camera is off so others cannot see you</p>
            <button @click="enableVideo" class="video-conferencing-room__video-button--on">
              <img src="/images/video_conferencing/camera-on.png" alt="">
              <span>Turn on camera</span>
            </button>
          </div>

          <div v-if="videoEnabled" class="video-conferencing-room__video-button-container">
            <p>Your camera is on so others can see you</p>
            <button @click="disableVideo('room')" class="video-conferencing-room__video-button--off">
              <img src="/images/video_conferencing/camera-off.png" alt="">
              <span>Turn off camera</span>
            </button>
          </div>

          <div class="video-conferencing-room__video-button-container">
            <p>Are you experiencing difficulties with this call?</p>
            <router-link to="/enquiries/get-help" tag="button" class="video-conferencing-room__video-button--setting">
              <img src="/images/video_conferencing/help.png" alt="">
              <span>Get Support</span>
            </router-link>
          </div>

          <div class="video-conferencing-room__video-button-container">
            <p>Do you want to leave the call to change your settings?</p>
            <button @click="changeSettings" class="video-conferencing-room__video-button--setting">
              <img src="/images/video_conferencing/help.png" alt="">
              <span>Change Settings</span>
            </button>
          </div>

        </div>

        <!-- If Admin and one to one -->
        <div v-if="(current_user_isAdmin && this.room.name == 'Adhoc Room')" class="video-room-container-custom__report-admin-panel--one-to-one">
          <p v-if="current_user_isAdmin && this.room.name == 'Adhoc Room'">This is an <strong>adhoc room</strong>. The room must be opened to allow users to join call. The room is currently <strong>{{ room.closed ? 'closed' : 'open'}}</strong>.</p>
          <p v-else>The room must be opened to allow users to join the call. The room is currently <strong>{{ room.closed ? 'closed' : 'open'}}</strong>.</p>

          <button
            v-if="room.closed"
            type="button"
            @click="toggleRoomOpening"
            class="video-room-container-custom__report-button--green"
          >
            <font-awesome-icon icon="fas fa-user-times" aria-hidden="true" role="presentation" />
            Open Room
          </button>

          <button
            v-else
            type="button"
            @click="toggleRoomOpening"
            class="video-room-container-custom__report-button"
          >
            <font-awesome-icon icon="fas fa-user-times" aria-hidden="true" role="presentation" />
            Close Room
          </button>
        </div>

        <section class="removed-user-notice" v-if="shouldShowRemovedUserNotice">
          <p> {{ removedUserNoticeText }} </p>
          <router-link to="/" tag="button" class="button green"> Return to homepage</router-link>
        </section>

        <div id="videoRoomContainer" style="width: 100%; height: 500px;" class="video-room-container-custom"></div>

        <span class="video-room-container-custom__reporting-triggers">
          <button v-if="current_user_isAdmin" class="video-room-container-custom__report-button" @click="showIncidentReportModule">
            <font-awesome-icon icon="fas fa-bullhorn" aria-hidden="true" role="presentation" />
            Report incident
          </button>

          <template v-if="!room.one_to_one && !shouldShowRemovedUserNotice">
            <button v-if="room.current_participants && room.current_participants.length > 1 && !current_user_isAdmin" type="button"
              @click="showReportUserForm = !showReportUserForm"
              class="video-room-container-custom__report-button"
              >
              <font-awesome-icon icon="fas fa-user-plus" aria-hidden="true" role="presentation"/>
              Report User
            </button>

            <button v-if="room.current_participants && blockedUsers.length > 0 && !current_user_isAdmin" type="button"
              @click="showUnblockUserForm = !showUnblockUserForm"
              class="video-room-container-custom__unblock-button"
              >
              <font-awesome-icon icon="fas fa-user-minus" aria-hidden="true" role="presentation"/>
              Unblock User
            </button>
          </template>
        </span>

          <template v-if="!room.one_to_one && !shouldShowRemovedUserNotice">
            <div v-if="showReportUserForm && !showUnblockUserForm" class="video-room-container-custom__report-user-panel">
              <select v-model="userBeingReported">
                <option value="" disabled selected>Select user</option>
                <option :key="participant.id" :value="participant.id" v-for="participant in other_room_participants_blockable">{{ participant.name }}</option>
              </select>

              <button type="button" class="video-room-container-custom__report-button--green" @click="reportUser">
                <font-awesome-icon icon="fas fa-user-times" aria-hidden="true" role="presentation"/>
                Block and Report User
              </button>
            </div>

            <div class="video-room-container-custom__report-user-panel" v-if="showUnblockUserForm">
              <select v-model="user_being_unblocked">
                <option value="" disabled selected>Select user</option>
                <option :key="participant.id" :value="participant.id" v-for="participant in other_room_participants_unblockable">{{ participant.name }}</option>
              </select>

              <button class="video-room-container-custom__report-button--green" @click="unblockUser">

                <font-awesome-icon icon="fas fa-user-times" aria-hidden="true" role="presentation"/>
                Unblock User
              </button>
            </div>

          <!-- If Admin and not one to one -->
            <div v-if="current_user_isAdmin" class="video-room-container-custom__report-admin-panel">
              <div :key="participant.id" v-for="participant in other_room_participants">
                {{ participant.name }} - {{ block_count(participant.id) }} Blocks

                <button
                  type="button"
                  @click="removeUser(participant)"
                  class="video-room-container-custom__report-button--green"
                >
                <font-awesome-icon icon="fas fa-user-times" aria-hidden="true" role="presentation" />
                Remove User
                </button>
              </div>
            </div>
          </template>

        <div id="localCameraContainer" style="width: 200px; height: 80px;"></div>

        <div
          v-if="current_user_isAdmin"
          class="video-conferencing-room__video-button-container video-conferencing-room__mute-all"
        >
          <p>If you wish to mute all participants in this call. Your device is not included.</p>
          <button
            type="button"
            @click="muteAll()"
            :class="this.muteAllUsers ? 'video-conferencing-room__video-button--off' : 'video-conferencing-room__video-button--on'"
          >
            <img
              :src="this.muteAllUsers ? '/images/video_conferencing/mic-off.png' : '/images/video_conferencing/mic-on.png'"
              :alt="this.muteAllUsers ? 'Mic off' : 'Mic on'"
            >
            {{ this.muteAllUsers ? 'Unmute all' : 'Mute all' }}
          </button>
        </div>

        <incident-report
          v-if="shouldShowIncidentReportForm && current_user_isAdmin"
          @hideIncidentReportModule="hideIncidentReportModule"
          :room="room"
          :participant="participant"
        ></incident-report>

        <div v-if="shouldShowRoomStillOpenModal" class="room-still-open-modal__overlay">

          <div role="alertdialog" aria-modal="true" aria-describedby="dialog_desc" class="room-still-open-modal">
            <div id="dialog_desc" class="room-still-open-modal__desc">
              <p>
                The room is still open for participants to join. Are you sure you want to leave the call before closing the room?
              </p>
            </div>
            <div class="room-still-open-modal__actions">
              <button ref="modalCancel" class="room-still-open-modal__cancel" @click="toggleRoomStillOpenModal">
                Cancel
              </button>
              <button ref="modalEnd" class="room-still-open-modal__end" @click="endCall">
                Yes, end call
              </button>
            </div>
          </div>
        </div>

      </main>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import IncidentReport from './components/reports/IncidentReport.vue';

export default {
  name: 'videoConferencingRoom',
  components: {
    logoBar,
    navigationButtons,
    IncidentReport
  },
  data: function () {
    return {
      room: { initialState: true },
      participant: {
        website_user_id: undefined,
        name: null,
        email: null,
        terms: false,
        is_adhoc_admin: undefined,
        is_scheduled_admin: undefined,
        profile_image_url: null,
      },
      preview_window: null,
      room_window: null,
      callMode: "precall",
      allDevices: [],
      showVideoSettings: false,
      showAudioSettings: false,
      videoEnabled: true,
      audioEnabled: true,
      currentVideoDevice: null,
      currentAudioDevice: null,
      audioLevel: 0,
      shouldShowIncidentReportForm: false,
      showReportUserForm: false,
      userBeingReported: null,
      blockedUsers: [],
      shouldShowRemovedUserNotice: false,
      removedUserNoticeText: 'Session disconnected',
      errorMessage: '',
      user_being_unblocked: null,
      showUnblockUserForm: false,
      joinedRoom: null,
      joinedName: null,
      shouldShowRoomStillOpenModal: false,
      roomCount: null,
      roomCountLoaded: false,
      muteAllUsers: false
    };
  },
  computed: {
    current_user_isAdmin() {
      if ((this.participant.is_adhoc_admin && !this.room.scheduled) || (this.participant.is_scheduled_admin && this.room.scheduled)) {
        return true
      } else {
        return false
      }
    },
    current_user_isScheduledAdmin() {
      if (this.participant.is_scheduled_admin && this.room.scheduled) {
        return true
      } else {
        return false
      }
    },
    other_room_participants() {
      if(this.room.current_participants) {
        return this.room.current_participants.filter(participant => participant.id);
      }
    },
    other_room_participants_blockable() {

      if(this.room.current_participants){
        return this.room.current_participants.filter(participant => participant.id && !this.blockedUsers.includes(participant.id));
      }
    },
    other_room_participants_unblockable() {
        if(this.room.current_participants){
        return this.room.current_participants.filter(participant => participant.id && this.blockedUsers.includes(participant.id));
      }
    }
  },
  methods: {
    block_count(participant_id) {
      if(this.room.reported_participants) {
        return this.room.reported_participants.filter(x => x == participant_id).length;
      } else {
        return 0
      }
    },

    showIncidentReportModule() {
      this.shouldShowIncidentReportForm = !this.shouldShowIncidentReportForm
    },

    hideIncidentReportModule() {
      this.shouldShowIncidentReportForm = !this.shouldShowIncidentReportForm
    },

    toggleRoomStillOpenModal() {
      this.shouldShowRoomStillOpenModal = !this.shouldShowRoomStillOpenModal
    },

    recordUserHasEnteredTheRoom() {
     if(this.room.uuid) {
        axios.put('/website_users/current_user.json', {  in_room_id: this.room.uuid  }).then((response) => {
      })
     }
    },
    loadRoomCount() {
      if(this.callMode == 'incall') {
        axios.get("/video_conference/rooms.json").then((response) => {
          let uuids = response.data.rooms.map(x => x.uuid);
          let otherRooms = uuids.filter(r => r !== this.room.uuid);
          this.roomCount = otherRooms.length;
          this.roomCountLoaded = true;
        })
      }
    },
    loadCurrentWebsiteUser() {
       axios.get("/website_users/current_user.json").then((response) => {
        if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
          this.participant.website_user_id = response.data.current_website_user.id
          this.participant.name = response.data.current_website_user.name
          this.participant.email = response.data.current_website_user.email
          this.participant.profile_image_url = response.data.current_website_user.profile_image_url
          this.participant.terms = true

          this.participant.is_adhoc_admin = response.data.current_website_user.is_adhoc_admin;
          this.participant.is_scheduled_admin = response.data.current_website_user.is_scheduled_admin;
        }
      })
    },
    loadPreviewWindow() {
      this.preview_window = new VideoExpress.PreviewPublisher('videoPreviewContainer');

      this.preview_window.previewMedia({
        targetElement: 'videoPreviewContainer',
        publisherProperties: {
          resolution: '1280x720',
          audioBitrate: 12000,
        }
      });

      this.preview_window.on("audioLevelUpdated", (audioLevel) => {
        this.audioLevel = audioLevel * 100.0;
      });

      this.preview_window.on("created", () => {
        this.updatePreviewVideoDevice();
        this.updatePreviewAudioDevice();
      });
    },
    updatePreviewVideoDevice() {
      if (this.videoEnabled) {
        this.preview_window.setVideoDevice(this.currentVideoDevice.deviceId);
        if (!(this.preview_window.isVideoEnabled())) {
          this.preview_window.enableVideo();
        }
      } else {
        if (this.preview_window.isVideoEnabled()) {
          this.preview_window.disableVideo();
        }
      }
    },
    updatePreviewAudioDevice() {
      if (this.audioEnabled) {
        this.preview_window.setAudioDevice(this.currentAudioDevice.deviceId);
        if (!(this.preview_window.isAudioEnabled())) {
          this.preview_window.enableAudio();
        }
      } else {
        if (this.preview_window.isAudioEnabled()) {
          this.preview_window.disableAudio();
        }
      }

    },
    updateRoomVideoDevice() {
      if (this.videoEnabled) {
        this.room_window.camera.setVideoDevice(this.currentVideoDevice.deviceId);
        if (!(this.room_window.camera.isVideoEnabled())) {
          this.room_window.camera.enableVideo();
        }
      } else {
        if (this.room_window.camera.isVideoEnabled()) {
          this.room_window.camera.disableVideo();
        }
      }
    },
    updateRoomAudioDevice() {
      if (this.audioEnabled) {
        this.room_window.camera.setAudioDevice(this.currentAudioDevice.deviceId);
        if (!(this.room_window.camera.isAudioEnabled())) {
          this.room_window.camera.enableAudio();
        }
      } else {
        if (this.room_window.camera.isAudioEnabled()) {
          this.room_window.camera.disableAudio();
        }
      }
    },
    closePreviewWindow() {
      this.preview_window.destroy();
    },
    enableVideo(option) {
      this.videoEnabled = true;
      localStorage.videoEnabled = true;
      if (this.callMode == "precall") {
        this.updatePreviewVideoDevice();
      } else {
        this.updateRoomVideoDevice();
      }
    },
    disableVideo(option) {
      this.videoEnabled = false;
      if(option === "room") {
        localStorage.videoEnabled = false;
      }
      if (this.callMode == "precall") {
        this.updatePreviewVideoDevice();
      } else {
        this.updateRoomVideoDevice();
      }
    },
    enableAudio(option) {
      this.audioEnabled = true;
      localStorage.audioEnabled = true;
      if (this.callMode == "precall") {
        this.updatePreviewAudioDevice();
      } else {
        this.updateRoomAudioDevice();
      }
    },
    disableAudio(option) {
      this.audioEnabled = false;
      if(option === "room") {
        localStorage.audioEnabled = false;
      }
      if (this.callMode == "precall") {
        this.updatePreviewAudioDevice();
      } else {
        this.updateRoomAudioDevice();
      }
    },

    validateJoiningRoom() {
      if(this.participant.name !== null && this.participant.terms == true) {
         if(this.room.current_participants.includes(this.participant.name)) {
            for (let i = 1; i < 10; i++) { //will only add numbers to duplicate names up to 10 duplicates, this feels sufficient for the size of calls/sessions
              let new_name = this.participant.name + ' (' + i + ')'
              if(!this.room.current_participants.includes(new_name)) {
                this.participant.name = new_name;
                { break; }
              }
            }
         }
        this.joinRoom();
      } else {
        this.errorMessage = "Please enter your name and accept terms of use to continue"

        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
      }
    },
    joinRoom() {
      this.closePreviewWindow();
      this.addActiveParticipant();
      this.callMode = "incall";
      this.joinedRoom = this.room.uuid;
      this.joinedName = this.participant.name;

      this.room_window = new VideoExpress.Room({
        apiKey: this.room.api_key, // add your OpenTok API key
        sessionId: this.room.video_session_id, // add your OpenTok Session ID
        token: this.room.video_token, // add your OpenTok token
        roomContainer: 'videoRoomContainer',
        participantName: this.participant.name,
        nameDisplayMode: "on",
        managedLayoutOptions: {
          layoutMode: this.room.presenter_mode ? 'active-speaker' : 'grid', // could be 'grid' or 'active-speaker'
          // cameraPublisherContainer: "localCameraContainer",
        },
      });

      this.room_window.join({
        publisherProperties: {
          style: {
            nameDisplayMode: "on",
          },
          name: this.participant.name,
        },

      });

      this.room_window.camera.on("created", () => {
        this.updateRoomVideoDevice();
        this.updateRoomAudioDevice();

        var buttons = document.getElementById("video-conferencing-room_incall");
        buttons.scrollIntoView();
      });
      this.loadRoomCount();

      if (this.room.name == "Adhoc Room") {
        this.room.closed = false;
      }

      // if user has been kicked by the admins
      // or if user connection has dropped
      // show a removed-user-notice message

      this.room_window.on("disconnected", (event) => {
        // console.log(event)
        this.shouldShowRemovedUserNotice = true;
      });

      this.room_window.on('signal', (event) => {
        const { type, data } = event;

        let participant = this.room_window.participants[event.from.id];

        if (!event.isSentByMe) {
          switch(type) {
            case 'signal:moderator-mute':
              this.audioEnabled = false;
              this.room_window.camera.disableAudio();
              break;
            case 'signal:moderator-unmute':
              this.audioEnabled = true;
              this.room_window.camera.enableAudio();
              break;
            case 'signal:block-user':
              participant.camera.disableVideo();
              participant.camera.disableAudio();
              break;
            case 'signal:unblock-user':
              participant.camera.enableVideo();
              participant.camera.enableAudio();
              break;
            case 'signal:close-room':
              this.room.closed = true;
              break;
            case 'signal:open-room':
              this.room.closed = false;
              break;
          }
        }
      });
    },
    changeSettings() {
      if (this.room_window) {
        this.room_window.leave();
      }
      document.getElementById('videoRoomContainer').innerHTML = "";
      this.callMode = "precall";
      this.loadPreviewWindow();
      // location.reload();
    },
    endCallPreliminary() {
      if(this.room.one_to_one == true && this.current_user_isAdmin && !this.room.closed) {
        this.toggleRoomStillOpenModal()
        this.$nextTick(() => this.$refs.modalCancel.focus())
      } else {
        this.endCall()
      }
    },
    endCall() {
      if (this.room_window) {
        this.disableVideo("end");
        this.disableAudio("end");
        this.room_window.leave();
        window.location.pathname = "/enquiry_forms/session_feedback/enquiries/new";
      }
      if (this.room.name == "Adhoc Room") {
        this.disableVideo("end");
        this.disableAudio("end");
        // this.$router.push({ path: '/cafe' });
        window.location.pathname = "/enquiry_forms/session_feedback/enquiries/new";
      } else {
        this.disableVideo("end");
        this.disableAudio("end");
        this.$router.push({ path: '/' });
      }
    },
    initializeDevices() {
      VideoExpress.getDevices().then((devices) => {
        this.allDevices = devices;
        this.currentVideoDevice = devices.find(d => d.kind == "videoInput");
        this.currentAudioDevice = devices.find(d => d.kind == "audioInput");
      });
    },
    loadRoom() {
      axios.get("/video_conference/rooms/" + this.$route.params.uuid + ".json").then((response) => {
        this.room = response.data;

        // If this room doesn't exist, it must have expired - let's just go back home and start again
        if (!this.room.uuid) {
          this.$router.push({ path: '/' });
        }
      })
    },
    updateRoomOpen() {
      if(this.room.one_to_one == true && this.callMode == 'precall') {
        axios.get("/video_conference/rooms/" + this.$route.params.uuid + ".json").then((response) => {
            this.room.available_to_join = response.data.available_to_join;
          })
      }
    },
    updateCurrentParticipants() {
      //get reported participants for admins
       if (this.current_user_isAdmin) {
        axios.get("/video_conference/rooms/" + this.$route.params.uuid + ".json").then((response) => {
          this.room.reported_participants = response.data.reported_participants;
        })
      }
      // if current user is admin, run axios request, to get report data,
      // otherwise, use javascript method for performance
      // OR if you haven't joined the room yet
      if (this.callMode == 'precall') {
        axios.get("/video_conference/rooms/" + this.$route.params.uuid + ".json").then((response) => {
          this.room.current_participants = response.data.current_participants;
        })
      } else {
        if (this.room_window && this.room) {
          let participants = [{
            id: null,
            name: this.participant.name,
          }]
          let keys = Object.keys(this.room_window.participants);
          keys.forEach(key => {
            let item = this.room_window.participants[key];
            let participant = {
              id: item.id,
              name: item.name,
            }
            participants.push(participant)
          })

          this.room.current_participants = participants;
        }
      }

    },
    addActiveParticipant() {
      axios.put("/video_conference/rooms/" + this.room.uuid + "/add_room_participant.json", { participant_id: this.participant.website_user_id, name: this.participant.name, profile_image_url: this.participant.profile_image_url  }).then((response) => {

      });
    },
    removeActiveParticipant() {
       axios.put("/video_conference/rooms/" + this.joinedRoom + "/remove_room_participant.json", { name: this.joinedName  }).then((response) => {

      });
    },
    reportUser() {
      let participant = this.room_window.participants[this.userBeingReported];
      // If participant exists block camera and audio and send to Rails with axios
      if(participant) {
        participant.camera.disableVideo();
        participant.camera.disableAudio();
        axios.put("/video_conference/rooms/" + this.room.uuid + "/report_user.json", { participant_id: participant.id  }).then((response) => {
          this.blockedUsers.push(participant.id);
        });
      }

      this.room_window.signal({
        type: "block-user",
      });

      this.userBeingReported = null;
    },
    unblockUser() {
      let participant = this.room_window.participants[this.user_being_unblocked]

      // If participant exists unblock camera and audio send to Rails with axios
      if(participant) {
        participant.camera.enableVideo();
        participant.camera.enableVideo();
        axios.put("/video_conference/rooms/" + this.room.uuid + "/unblock_user.json", { participant_id: participant.id  }).then((response) => {
          this.blockedUsers = this.blockedUsers.filter(d => d !== participant.id)
          this.showUnblockUserForm = false;
        });
      }

      this.room_window.signal({
        type: "unblock-user",
      });

      this.user_being_unblocked = null;
    },
    removeUser(participant) {
      axios.put("/video_conference/rooms/" + this.room.uuid + "/remove_user.json", { participant_id: participant.id  }).then((response) => {
      });
    },
    toggleRoomOpening() {
      this.room.closed = !this.room.closed;
      axios.put("/video_conference/rooms/" + this.room.uuid + "/toggle_room_opening.json").then((response) => {
      });

      this.room_window.signal({
        type: this.room.closed ? "close-room" : "open-room",
      });
    },
    showTextNoticeForKickedUsers() {
      this.shouldShowRemovedUserNotice = !this.shouldShowRemovedUserNotice
    },
    participant_profile_image(room_participants, participant) {
      let image_url = '/images/video_conferencing/buddies_notification.png';
      if(room_participants) {
        room_participants.forEach(room_participant => {
          if (Object.values(room_participant).includes(participant)) {
            if(room_participant.image_url) {
              image_url = room_participant.image_url
            }
          }
        })
      }
      return image_url;
    },
    toLoginWithRoomUUID() {
      this.$router.push({name: "login", params: {uuid: this.room.uuid}});
    },
    toRegisterWithRoomUUID() {
      this.$router.push({name: "register", params: {uuid: this.room.uuid}});
    },
    muteAll() {
      this.muteAllUsers = !this.muteAllUsers;

      for (const participant in this.room_window.participants) {
        if (participant != this.room_window.participantId) {
          if (!this.muteAllUsers) {
            this.room_window.signal({
              type: "moderator-unmute",
            });
          } else {
            this.room_window.signal({
              type: "moderator-mute",
            });
          }
        }
      }
    },
  },
  watch: {
    callMode(newCallMode, oldCallMode) {
      if (oldCallMode === 'incall' && newCallMode === 'precall') {
        this.removeActiveParticipant();
      }
    },
    joinedRoom(newJoinedRoom) {
      localStorage.joinedRoom = newJoinedRoom;
    },
    joinedName(newJoinedName) {
      localStorage.joinedName = newJoinedName;
    },
  },
  created() {
      this.loadRoom();
  },
  mounted: function() {
    this.$nextTick(function () {
      if (localStorage.joinedRoom) {
        this.joinedRoom = localStorage.joinedRoom;
      }
      if (localStorage.joinedName) {
        this.joinedName = localStorage.joinedName;
        this.participant.name = localStorage.joinedName;
      }

      if (localStorage.getItem("videoEnabled") !== null) {
        this.videoEnabled = localStorage.videoEnabled === "true" ? true : false;
      } else {
        localStorage.videoEnabled = this.videoEnabled;
      }

      if (localStorage.getItem("audioEnabled") !== null) {
        this.audioEnabled = localStorage.audioEnabled === "true" ? true : false;
      } else {
        localStorage.audioEnabled = this.audioEnabled;
      }
      this.initializeDevices();
      this.loadPreviewWindow();
      this.loadCurrentWebsiteUser();
      this.recordUserHasEnteredTheRoom();

      if(this.joinedRoom === this.$route.params.uuid) {
        this.removeActiveParticipant();
      }

      this.participantPolling = setInterval(function () {
        this.updateCurrentParticipants();
      }.bind(this), 13000);

      this.roomPolling = setInterval(function () {
        this.recordUserHasEnteredTheRoom();
      }.bind(this), 23000);

      this.roomOpenPolling = setInterval(function () {
        this.updateRoomOpen();
      }.bind(this), 7000);

      this.roomCountPolling = setInterval(function () {
        this.loadRoomCount();
      }.bind(this), 19000);




    });

  },


  beforeDestroy() {
    // this.recordUserHasLeftTheRoom(); // not needed as rails will look at timestamps to determin presence in room
    clearInterval(this.participantPolling);
    clearInterval(this.roomPolling);
    clearInterval(this.roomOpenPolling);
    clearInterval(this.roomCountPolling);
    this.removeActiveParticipant();
    localStorage.removeItem('joinedRoom');
    localStorage.removeItem('joinedName');

    if(this.callMode == 'incall') {
      this.room_window.leave();
    }
  }
}
</script>


<style scoped lang="scss">
  .video-conferencing-room__navigation {
    @media screen and (min-width: 1500px) {
      flex-wrap: nowrap;
      justify-content: center;
    }
  }

   .video-room-container-custom {
     display: flex;
     flex-direction: column-reverse;
   }

   .video-room-container-custom__reporting-triggers {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     margin: 10px;
   }

    .video-room-container-custom__report-button {
      text-align: center;
      border: none;
      color: white;
      padding: 8px 10px;
      background: #D2014A;
      max-width: 280px;
      // margin: 0 auto;
      border-radius: 5px;

      margin: 10px;

      &:hover {
        background: darken(#D2014A, 10%);
      }


      svg {
        margin-right: 10px;
      }

     }

     .video-room-container-custom__unblock-button {
      text-align: center;
      background: #fca44b;
      border: none;
      color: white;
      padding: 8px 10px;
      max-width: 280px;
      // margin: 0 auto;
      border-radius: 5px;

      margin: 10px;

      &:hover {
        background: darken(#fca44b, 30%);
      }


      svg {
        margin-right: 10px;
      }

     }

     .login-notice__ctas {
        display: flex;
        margin-bottom: 25px;
        .button.green {
          margin-right: 25px;
        }

        @media (max-width: 450px) {
          flex-direction: column;

          .button.green {
            margin-right: 0px;
            margin-bottom: 25px;
          }
        }
     }



    .video-room-container-custom__report-button--green {
      text-align: center;
       background: #028001;
      border: none;
      color: white;
      padding: 10px 10px;
      max-width: 280px;
      // margin: 0 auto;
      border-radius: 5px;

      margin: 10px;

      svg {
        margin-right: 10px;
      }

      &:hover {
        background: darken(#028001, 30%);
      }


     }


     .video-room-container-custom__report-user-panel {

       margin: 20px;

        select {
          height: 40px;
          width: 150px;
          margin-right: 10px;

          &:target, &:focus {
            outline: 3px dashed red;
          }
        }
     }


     .video-room-container-custom__report-admin-panel, .video-room-container-custom__report-admin-panel--one-to-one {
       margin:20px;
     }

     .video-room-container-custom__report-admin-panel--one-to-one {
       text-align: center;
     }

     .removed-user-notice {
       display: flex;
       justify-content: center;
     }

    .error-message {
      background: #fca44b;
      padding: 10px;
      color: white;
      margin-bottom: 20px;

      p {
        margin-bottom: 0;
        text-align:center;

      }
    }
</style>
