<template>
    <div class="now-play__popular-videos_page--wrapper">
        <logoBar></logoBar>
        <div class="now-play__popular-videos_page">
            <navigationButtons></navigationButtons>
            <loading v-if="!isLoaded"></loading>

            <a @click="$router.go(-1)" class="page-navigation__back">
                <font-awesome-icon icon="fas fa-angle-double-left" aria-hidden="true" role="presentation" />
                go back
            </a>

            <main id="content">
                <div class="now-play__popular-videos_page--title">
                    <header>
                        <span class="line blue-line"></span>
                        <h1> All Popular Videos</h1>
                    </header>
                </div>
                <div class="now-play__popular-videos_page--videos" v-if="videos.length > 0">
                    <single-video 
                        v-for="(video, index) in videos" 
                        :key="index"
                        :video="video"
                        :isPopularVideo="true"
                    ></single-video>
                </div>

                <div v-else class="now-play__no-videos-note">
                    <p> There are currently no videos available</p>
                </div>  
            </main>
        </div>
    </div>
</template>

<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import Loading from '../shared/loading.vue';
import SingleVideo from './secondary/SingleVideo.vue';


export default {
    name: 'PopularVideos',
    
    components: {
        logoBar,
        navigationButtons,
        Loading,
        SingleVideo
    },

    data() {
        return {
            currentUser: [],
            // documents: [],
            videos: [],
            isLoggedIn: false,
            isLoaded: false,

        }
    },
    
    created() {
        this.loadCurrentWebsiteUser();
        this.loadVideos();
    },

    methods: {
        futureAlert() {
            alert('To be added at a later point');
        },
        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.isLoaded   = true;
                    this.currentUser = response.data.current_website_user;
                } else {
                    this.isLoaded   = true;
                }
            })
        }, 
        
        loadVideos() {
              axios.get('documents/popular.json').then((response) => {

                    // get all documents - if use all documents endpoint
                    // this.documents = response.data.documents;

                    //  this.videos = this.documents.filter(document => document.views > 5);
                    this.videos = response.data.popular_documents;
                });
        }
    },
}
</script>


<style lang="scss" scoped>

    .now-play__popular-videos_page {
        min-height: 800px;
        margin-top: 10px;
        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }

    .now-play__popular-videos_page--title {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        vertical-align: middle;

        header {
            display: flex;
            align-items: center;

            h1 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }
            
        }
    }
    .now-play__popular-videos_page--videos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
</style>