<template>
    <div class="loading-wrapper">
      <div class="loading" role="status" aria-live="polite" aria-busy="true"></div>
      <p v-if="!customText"> Loading...</p>
      <p v-else> {{ customText }} </p>
   </div>
</template>

<script>
export default {
    name:'loading',
    props: ['customText']
}
</script>

<style lang="scss" scoped>

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 100%;

  p {
      margin-top: 20px;
      font-size: 18px;
      color: #212529;
  }

}
  .loading {
    animation: spin 2s infinite linear;
    border: 10px solid rgb(190, 190, 190);
    border-top: 10px solid rgb(72, 7, 120);
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>