<template>
  <section class="sessions-table-component">
    <table
      class="sessions-table-component__this-week"
      v-if="roomsThisWeek.length > 0"
    >
      <tr>
        <th>
          <font-awesome-icon
            icon="fa-calendar"
            aria-hidden="true"
            role="presentation"
          />
          This week's Schedule
        </th>
      </tr>
      <tr>
        <td v-for="(room, uuid) in roomsThisWeek" :key="uuid">
          <h3>{{ room.name }}</h3>
          <p>
            {{ room.descriptive_start_date }} at {{ room.casual_start_time }}
          </p>
          <p v-show="room.short_description">
            {{ room.short_description.slice(0, 130) }}...
          </p>
          <p
            v-show="room.participant_limit && room.participant_limit == null"
            class="participant-count"
          >
            {{ room.participant_count }} participants so far
          </p>
          <p
            v-show="room.participant_limit && room.participant_limit !== null"
            class="participant-count"
          >
            {{ room.participant_count }} /
            {{ room.participant_limit }} participants
          </p>
          <span class="video-conferencing__session-card-buttons">
            <p
              class="video-conferencing__session-card-non-bookable"
              v-if="!room.bookable"
            >
              You must be logged in to book this session
            </p>
            <router-link
              v-if="
                !room.current_user_booked &&
                (room.participant_limit === null ||
                  room.participant_count < room.participant_limit) &&
                room.bookable
              "
              :to="'/book/' + room.uuid"
              tag="button"
              class="video-conferencing__session-book"
              role="button"
              @keydown.enter="handleEnterKey"
              @keydown.space.prevent="handleSpaceKey"
              tabindex="0"
            >
              Book Now
            </router-link>

            <button
              class="video-conferencing__session-cancel"
              v-if="
                room.current_user_booked &&
                !shouldShowCancelBookingWarning &&
                !room.joinable
              "
              @click="prepareToCancelBooking()"
            >
              Cancel Booking
            </button>

            <button
              class="video-conferencing__session-cancel"
              v-if="room.current_user_booked && shouldShowCancelBookingWarning"
              @click="cancelBooking(room)"
            >
              Are you sure?
            </button>

            <button
              class="video-conferencing__session-join"
              v-if="room.current_user_booked && room.joinable"
              @click="joinRoom(room)"
            >
              Join Session
            </button>

            <button
              @click="toggleIndividualSessionModal(room)"
              v-if="
                room.participant_limit === null ||
                room.participant_count < room.participant_limit ||
                room.current_user_booked
              "
              class="video-conferencing__session-more"
            >
              More Info
            </button>

            <template
              v-if="
                !room.current_user_booked &&
                room.participant_limit &&
                room.participant_count === room.participant_limit
              "
            >
              <p class="video-conferencing__session-full">Full!</p>
            </template>
          </span>
        </td>
      </tr>
    </table>

    <table
      class="sessions-table-component__next-week"
      v-if="roomsNextWeek.length > 0"
    >
      <tr>
        <th>
          <font-awesome-icon
            icon="fa-calendar"
            aria-hidden="true"
            role="presentation"
          />
          Next Week's Schedule
        </th>
      </tr>

      <tr>
        <td v-for="(room, uuid) in roomsNextWeek" :key="uuid">
          <h3>{{ room.name }}</h3>
          <p>
            {{ room.descriptive_start_date }} at {{ room.casual_start_time }}
          </p>
          <p v-show="room.short_description">
            {{ room.short_description.slice(0, 130) }}...
          </p>
          <p
            v-show="room.participant_limit && room.participant_limit == null"
            class="participant-count"
          >
            {{ room.participant_count }} participants so far
          </p>
          <p
            v-show="room.participant_limit && room.participant_limit !== null"
            class="participant-count"
          >
            {{ room.participant_count }} /
            {{ room.participant_limit }} participants
          </p>

          <span>
            <p
              class="video-conferencing__session-card-non-bookable"
              v-if="!room.bookable"
            >
              You must be logged in to book this session
            </p>
            <router-link
              v-if="
                !room.current_user_booked &&
                (room.participant_limit === null ||
                  room.participant_count < room.participant_limit) &&
                room.bookable
              "
              :to="'/book/' + room.uuid"
              tag="button"
              class="video-conferencing__session-book"
            >
              Book
            </router-link>

            <button
              class="video-conferencing__session-cancel"
              v-if="room.current_user_booked && !shouldShowCancelBookingWarning"
              @click="prepareToCancelBooking()"
            >
              Cancel Booking
            </button>

            <button
              class="video-conferencing__session-cancel"
              v-if="room.current_user_booked && shouldShowCancelBookingWarning"
              @click="cancelBooking(room)"
            >
              Are you sure?
            </button>

            <button
              @click="toggleIndividualSessionModal(room)"
              v-if="
                room.participant_limit === null ||
                room.participant_count < room.participant_limit ||
                room.current_user_booked
              "
              class="video-conferencing__session-more"
            >
              More
            </button>

            <template
              v-if="
                !room.current_user_booked &&
                room.participant_limit &&
                room.participant_count === room.participant_limit
              "
            >
              <p class="video-conferencing__session-full">Full!</p>
            </template>
          </span>
        </td>
      </tr>
    </table>
    <individual-session-modal
      v-if="shouldShowIndividualSessionModal == true"
      :room="openedSession"
      :participant="participant"
      @closeIndividualSessionModal="toggleIndividualSessionModal()"
    ></individual-session-modal>
  </section>
</template>

<script>
import axios from "axios";
import IndividualSessionModal from "./IndividualSessionModal.vue";

export default {
  components: { IndividualSessionModal },
  name: "SessionsTable",
  props: ["roomsThisWeek", "roomsNextWeek"],

  data() {
    return {
      participant: {
        website_user_id: undefined,
      },
      shouldShowIndividualSessionModal: false,
      openedSession: {},
      shouldShowCancelBookingWarning: false,
    };
  },
  created() {
    this.loadCurrentWebsiteUser();
  },
  methods: {
    loadCurrentWebsiteUser() {
      axios.get("/website_users/current_user.json").then((response) => {
        if (
          response.data &&
          response.data.current_website_user &&
          response.data.current_website_user.logged_in
        ) {
          this.participant.website_user_id =
            response.data.current_website_user.id;
        }
      });
    },

    toggleIndividualSessionModal(room) {
      this.openedSession = room;
      this.shouldShowIndividualSessionModal =
        !this.shouldShowIndividualSessionModal;
    },

    prepareToCancelBooking() {
      this.shouldShowCancelBookingWarning =
        !this.shouldShowCancelBookingWarning;
    },

    joinRoom(room) {
      this.$router.push({ path: "/join/" + room.uuid });
    },

    cancelBooking(room) {
      axios
        .delete(
          "/video_conference/rooms/" +
            room.uuid +
            "/participants/" +
            this.participant.website_user_id +
            ".json"
        )
        .then((response) => {
          if (response.data.success) {
            room.current_user_booked = false;
            room.participant_count -= 1;
          } else {
            // Error?
          }
        });
    },
    handleEnterKey() {
      this.$router.push("/book/" + this.room.uuid);
    },
    handleSpaceKey() {
      this.$router.push("/book/" + this.room.uuid);
    },

    futureAlert() {
      alert("Future work - to be added later");
    },
  },
};
</script>
