<template>
     <div 
        class="now-play__single-category-expanded"
        :class=" {
            'orange-background' : category.name.includes('Fab'), 
            'green-background'  : category.name.includes('Children') ,
            'blue-background'   : category.name.includes('Training'),
            'white-background'  : category.isOther === true
        }"
    >
        <div class="now-play__single-category-expanded--title" id="categoryTitle">
            <h4> {{ category.name }} </h4>
        </div>

        <div class="now-play__single-category-expanded__videos">

            <div class="now-play__single-category-expanded__videos--inner">
                <!-- FABLAB -->
               <div class="videos-inner__single"> 
                   <div class="videos-inner__single--inside" id="Fablab">
                        <span v-if="category.name.includes('Fab')">
                            <single-video v-for="(video, index) in Fablab" :key="index" :video="video"> </single-video>
                        </span>
                   </div>

                    <div class="now-play__cta-controls" v-if="category.name.includes('Fab') && Fablab.length > 4">
                        <button class="orange-background" @click="scrollLeft('Fablab')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                        <button class="orange-background" @click="scrollRight('Fablab')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                    </div>
               </div>
                <!-- ChildrensLibrary -->
                <div class="videos-inner__single"> 
                   <div class="videos-inner__single--inside" id="ChildrensLibrary">
                        <span v-if="category.name.includes('Children')">
                            <single-video v-for="(video, index) in ChildrensLibrary" :key="index" :video="video"> </single-video>
                        </span>
                   </div>

                    <div class="now-play__cta-controls" v-if="category.name.includes('Children') && ChildrensLibrary.length > 4">
                        <button class="green-background" @click="scrollLeft('ChildrensLibrary')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                        <button class="green-background" @click="scrollRight('ChildrensLibrary')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                    </div>
                </div>

                <!-- TrainingPod -->
                <div class="videos-inner__single">

                    <div class="videos-inner__single--inside" id="TrainingPod">
                        <span v-if="category.name.includes('Training')">
                            <single-video v-for="(video, index) in TrainingPod" :key="index" :video="video"> </single-video>
                        </span>
                    </div>

                    <div class="now-play__cta-controls" v-if="category.name.includes('Training') && TrainingPod.length > 4">
                        <button class="blue-background" @click="scrollLeft('TrainingPod')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                        <button class="blue-background" @click="scrollRight('TrainingPod')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                    </div>

                </div>

                <!-- additionalCategories -->
                <div class="videos-inner__single">

                   <div class="videos-inner__single--inside" id="additionalCategories">
                        <span v-if="category.isOther && additionalCategories.length > 0">                  
                            <single-category v-for="(category, index) in additionalCategories" :key="index" :category="category"></single-category>
                        </span>
                   </div>

                    <div class="now-play__cta-controls" v-if="category.isOther && additionalCategories.length > 4">
                        <button class="white-background" @click="scrollLeft('additionalCategories')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                        <button class="white-background" @click="scrollRight('additionalCategories')">
                            <i aria-hidden="true" role="presentation">
                                <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                            </i>
                        </button>
                    </div>
                </div>

                <span v-if="category.isOther && additionalCategories.length == 0">
                    <p style="margin-top: 30px"> No additional categories are available at the moment </p>
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import SingleCategory from './SingleCategory.vue'
import SingleVideo from './SingleVideo.vue'

export default {
  components: { SingleVideo, SingleCategory },
    name: 'SingleCategoryExpanded',
    props: ['category', 'Fablab', 'ChildrensLibrary', 'TrainingPod', 'additionalCategories'],
    methods: {
        scrollLeft(ref) {
            const element = document.getElementById(`${ref}`);

            if (element) {
                element.scrollBy({left: -340, behavior: "smooth"});
            }
        },

        scrollRight(ref) {
            const element = document.getElementById(`${ref}`);

            if (element) {
                element.scrollBy({left: 340, behavior: "smooth"});
            }
        }
    }
}
</script>

<style scoped lang="scss">

    .orange-background {
        background-color: #d37d0d;
    }

    .green-background {
        background-color: #88c054;
    }

    .blue-background {
        background-color: #1e93be;
    }

    .white-background {
        background-color: #ededed;
    }

    .videos-inner__single {
        display: flex;
        flex-direction: column;
    }

    .now-play__single-category-expanded__videos--inner,
    .videos-inner__single--inside {
        position: relative;
    }


    .now-play__cta-controls {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        display: flex;

        button {
            outline: none;
            border: 0;
            padding: 10px 15px;
            color: #595959;

            &:hover,
            &:focus {
                outline: 2px solid #333333;
            }
        }

        i {
            display: flex;
            justify-content: center;

            svg {
                color: #cbcbcb;
                height: 25px;
            }
        }
    }

</style>