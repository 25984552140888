<template>
    <section class="nowplay-search-modal__wrapper">
        <logoBar></logoBar>
        <navigationButtons></navigationButtons>

        <a @click="$router.go(-1)" class="page-navigation__back">
            <font-awesome-icon icon="fas fa-angle-double-left" aria-hidden="true" role="presentation" />
            go back
        </a>

        <video-player v-if="shouldShowVideoPlayer" :video="videoToPlay" @close="toggleVideoPlayer"></video-player>
       <main class="nowplay-search-modal" id="content">
            <div class="nowplay-search-modal__header">

                 <aside class="search-wrapper__text">
                    <h1> NowPlay Search</h1>
                    <span>Our video bank has something for everyone.</span>
                </aside>
            </div>

            <div class="nowplay-search-modal__searching">

                <aside class="search-wrapper__search-box">
                    <input 
                        type="search" 
                        placeholder="What are you looking for?" 
                        aria-label="What are you looking for?" 
                        role='search' size="25" 
                        ref="search" 
                        v-model="search_term"
                        v-on:keyup.enter="loadVideos"
                    >
                    <font-awesome-icon icon="fas fa-magnifying-glass"  class="search-icon" aria-hidden="true" role="presentation"/>
                </aside>
            </div>

            <div class="nowplay-search-modal__results">
                <loading v-show="!isLoaded" :customText="'Searching...'"></loading>
                <p v-show="isLoaded"> <strong> {{textToDisplay}} </strong> </p>

                <article class="nowplay-search-modal__results-item" v-for="(document,index) in documents" :key="index">
                    <aside class="nowplay-search-modal__results-item__image">
                        <template v-if="document.thumbnail_image">
                            <img :src="document.thumbnail_image" :alt="[document.thumbnail_alt_text ? document.thumbnail_alt_text : 'nowplay video']">
                        </template>
                         <template v-else>
                            <img :src="document.thumbnail_url" :alt="[document.thumbnail_alt_text ? document.thumbnail_alt_text : 'nowplay video' ]">
                        </template>
                    </aside>
                    <aside class="nowplay-search-modal__results-item__details">
                        <h3> {{ document.name.slice(0,90)}} </h3>
                        <p v-if="document.description"> {{ document.description.slice(0,120) }}... </p>

                        <div class="nowplay-search-modal__results-item__details--watch">
                            <button @click="toggleVideoPlayer(document)">
                                <font-awesome-icon icon="fas fa-play-circle" aria-hidden="true" role="presentation"/>
                                Watch on NowPlay Video Player
                            </button>
                        </div>
                    </aside>
                </article>
            </div>
       </main>
    </section>
</template>
    
<script>
import axios from 'axios';
import loading from '../shared/loading.vue';
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import VideoPlayer from './VideoPlayer.vue';

export default {
    name: "NowPlaySearchPage",
    props: ['term'],
    components: { 
        loading,
        logoBar,
        navigationButtons,
        VideoPlayer,
    },
    data() {
        return {
            page_count: 1,
            per_page: 10,
            documents: [],
            textToDisplay: '',
            isLoaded: false,
            videoToPlay: null,
            shouldShowVideoPlayer: false,
            search_term: undefined
        }
    },
    created() {
        // avoids direct prop mutation
        this.search_term = this.term;

        // this will show all videos, as user hasn't typed a term
        if (this.term === 'all-videos') {
            this.search_term = ''
        }
    },
    mounted() {
        this.loadVideos();
    },
    methods: {
        loadVideos() {
                
            axios.get('/documents.json?page=' + this.page_count + '&per_page=' + this.per_page + '&search_document%5Bterm%5D=' + this.search_term).then((response) => {

                this.isLoaded = false;

                if(response.data.documents) {
                    this.documents = response.data.documents;
                }
                
                // user hasnt typed anything in the search
                if(this.search_term == undefined) {
                    this.textToDisplay = " Please type a search term to find NowPlay videos"
                }

                // user has typed their term but we found nothing
                else if(this.documents.length === 0) {
                    this.textToDisplay = 'No videos found. Try amending your search term and try again'
                }

                // found one video - grammar
                else if(this.documents.length == 1) {
                    this.textToDisplay = 'We found ' + this.documents.length + ' video for this search'
                }

                // found more than one video - grammar
                else {
                    this.textToDisplay = 'We found ' + this.documents.length + ' videos for this search'
                }
            

                this.isLoaded = true;
            });

        },

        toggleVideoPlayer(document) {
            this.videoToPlay = document;
            this.shouldShowVideoPlayer = !this.shouldShowVideoPlayer;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>