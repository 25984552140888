<template>
    <div v-show="shouldShowManualUpload" :class="{'registration-form__image-settings--computer-upload d-flex align-center' : shouldShowManualUpload }">
        <!-- PREVIOUSLY SET PROFILE PICTURE -->
        <!--- or a preview if unset before -->
        <div class="computer-upload__profile-image mt-2 mr-4 d-flex justify-center align-center">
            <img v-if="participant.avatar_url == '' " src="/images/video_conferencing/profile.png"  alt="circular shaped dark coloured silouette of a person">
            <img ref="avatar_preview" v-else :src="participant.avatar_url" :alt="participant.name + '\'s profile image' "/>
        </div>

        <!-- SET NEW PROFILE PICTURE -->
        <form class="computer-upload__profile-image--upload my-4 d-flex flex-column align-start" aria-labelledby="rofileImageCollumnTitle">
            <label for="profilePictureUploadField"> Click here to upload a profile image: </label>
            <input @change="setProfileImageAsUploadedFile" ref="imageUploadInput" type="file" name="profilePictureUploadField" accept="image/gif, image/jpeg, image/png, image/jpg">
            <button v-if="this.uploaded_file" @click="removeImage" class="button green">Remove image</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'ComputerImageUpload',
        props: ['shouldShowManualUpload', 'currentUser'],

        data() {
            return {
                participant: {
                    name: '',
                    email: '',
                    avatar_url: '',
                    avatar_data: '',
                },
                uploaded_file: false
            }
        },

        created() {
            // stops prop mutation
            this.participant = this.currentUser;
        },

        watch: {
            shouldShowManualUpload(newValue) {
                // console.log('running watcher')
                if(newValue == false) {
                    this.uploaded_file = false;
                    this.$refs.imageUploadInput.value = null;
                }
            }
        },
        
        methods: {
            setProfileImageAsUploadedFile(event) {
      
                this.$emit('resetCurrentUserAvatarURL');

                const imageToUpload = event.target.files[0];

                this.participant.avatar_url = URL.createObjectURL(imageToUpload);
                this.uploaded_file = true;

                let reader = new FileReader();
                reader.readAsDataURL(imageToUpload);
                reader.onload = () => {
                    this.participant.avatar_data = reader.result;
                };
            },
            removeImage() {
                this.uploaded_file = false;
                this.$emit('resetCurrentUserAvatarURL');
        
                this.$refs.imageUploadInput.value = null;
            }
        }
    }
</script>

<style lang="scss" scoped>

    /// IMAGE UPLOAD/PREVIEW 
    .computer-upload__profile-image {
        img {
            width: 100px;
            height: 100px;
        }
    }

    @media (max-width: 780px) {

        .registration-form__image-settings--computer-upload {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .computer-upload__profile-image--upload {
            text-align: center;
        }
    }

</style>