import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import api from "../api";

// import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      name: null,
      logged_in: false,
      email: null,
      allow_friendships: false,
      is_adhoc_admin: false,
      is_scheduled_admin: false,
    }
  },
  getters: {
    isAuthenticated: state => state.user.logged_in,
  },
  mutations: {
    setUser(state, currentUser) {
      Object.assign(state.user, currentUser);
    },
    clearUser(state) {
      state.user.logged_in = false;
    },
  },
  actions: {
    loadUserFromSession({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .loadUser()
          .then(response => {
            commit("setUser", response.data.current_website_user);
            resolve();
          })
          .catch(error => {
            // if the request fails, clear user
            commit("clearUser");
            reject(error.response);
          });
      });
    },
    signIn({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .login(params.email, params.password)
          .then(response => {
            commit("setUser", response.data.current_website_user);
            resolve();
          })
          .catch(error => {
            // if the request fails, clear user
            commit("clearUser");
            reject(error.response);
          });
      });
    },
    signOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit("clearUser");

        api
          .logout()
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    passwordReset({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .resetPassword(params.reset_token, params.password, params.password_confirmation)
          .then(response => {
            commit("setUser", response.data.current_website_user);
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    register({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .registration(params.formData)
          .then(response => {
            commit("setUser", response.data.current_website_user);
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    update({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .updateProfile(params.formData)
          .then(response => {
            commit("setUser", response.data.current_website_user);
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
  }
})
