<template>

    <section class="forgotten-password">     

        <div class="forgotten-password--nav">
            <logoBar></logoBar>
            <navigationButtons class="video-conferencing__navigation-buttons"></navigationButtons>
        </div>    

        <main id="content">

            <header>
                <h1> 
                    <font-awesome-icon icon="fa-solid fa-key" aria-hidden="true" role="presentation"/>
                    Forgotten Password 
                </h1>
            </header>

            <form class="forgotten-password__form">
                
                <fieldset>
                    <input type="email" id="email" name="email" v-model="emailAddress" aria-required="true" required autocomplete="email" placeholder="e.g John@gmail.com" :aria-invalid="emailAddressNotSet">
                    <label for="email" > Enter your email address: <span class="required-star"> * </span></label>
                </fieldset>

                <button @click.prevent="sendInstructions" aria-live="polite" :aria-busy="sending">
                    <template v-if="!sending">
                        Send me the instructions
                    </template>
                    <template v-else>
                        Sending instructions...
                    </template>                 
                </button>

                <div class="buddies__message--error" aria-live="assertive" v-if="email_not_found">
                    Sorry, we were unable to locate your email address in our records. Please <router-link :to="'/'">contact our team</router-link> for further assistance.
                </div>

                 <div class="buddies__message--error" aria-live="assertive" v-if="emailAddressNotSet && emailAddress == '' ">
                    Please enter your email address before continuing
                </div>
            </form>

            <p> Alternatively, you can log in or create a new account</p>

            <section class="forgotten-password__redirects">
                <router-link to="/login" tag="button" class="button green"> Login</router-link>
                <router-link to="/register" tag="button" class="button blue"> Register </router-link>
            </section>
        </main>   
    </section>

</template>


<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';

export default {
    name: 'ForgottenPassword',
    data() {
        return {
            emailAddress: '',
            email_not_found: false,
            sending: false,
            emailAddressNotSet: false
        }
    },
    components: {
        navigationButtons,
        logoBar
    },
    methods: {
        sendInstructions() {
            
            this.email_not_found = false;
            let authenticity_token = document.head.querySelector('meta[name="csrf-token"]').content;
    
           if(this.emailAddress !== '') {
               this.sending = true;

                axios.post('/website_users/password.json', {
                    authenticity_token: authenticity_token,
                    website_user: {
                        email: this.emailAddress,
                    },
                    commit: "Send me reset password instructions"
                }).then((response) => {
                    this.$router.push({ path: '/login' });
                }).catch((response) => {
                    this.sending = false;
                this.email_not_found = true;
                });

           } else {
               this.sending = false;
               this.emailAddressNotSet = true
               document.querySelector('#email').focus();
           }
        },
    }
}
</script>

<style lang="scss" scoped>
    .buddies__message--error {
        display: block;
        margin-top: 20px;
        margin-bottom: 0;
    }
</style>

