import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import videoConferencingHome from 'javascripts/video_conferencing/home';
import videoConferencingRoomList from 'javascripts/video_conferencing/roomList';
import videoConferencingRoom from 'javascripts/video_conferencing/room';
import videoConferencingBookingForm from 'javascripts/video_conferencing/bookingForm';
import videoConferencingScheduledRoomList from 'javascripts/video_conferencing/scheduledRoomsList';
import RegistrationForm from 'javascripts/video_conferencing/components/account/registrationForm';
import InviteAFriend from 'javascripts/video_conferencing/components/invitations/InviteAFriend'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Buddies from 'javascripts/video_conferencing/components/buddies/Buddies.vue';
import NowPlayHomepage from 'javascripts/video_conferencing/components/now_play/NowPlayHomepage.vue';
import LatestVideos from 'javascripts/video_conferencing/components/now_play/LatestVideos.vue';
import PopularVideos from 'javascripts/video_conferencing/components/now_play/PopularVideos.vue';
import VideoCategories from 'javascripts/video_conferencing/components/now_play/VideoCategories.vue';
import VideoCategoryPage from 'javascripts/video_conferencing/components/now_play/VideoCategoryPage.vue';
import EditProfile from 'javascripts/video_conferencing/components/profile/EditProfile.vue';
import  NowPlaySearchPage  from 'javascripts/video_conferencing/components/now_play/NowPlaySearchPage.vue';
import Login from 'javascripts/video_conferencing/components/account/Login.vue';
import ForgottenPassword from 'javascripts/video_conferencing/components/account/ForgottenPassword.vue';
import ResetPassword from 'javascripts/video_conferencing/components/account/ResetPassword.vue';
import vueFooter from 'javascripts/video_conferencing/components/shared/vueFooter.vue';
import CmsPage from 'javascripts/video_conferencing/components/shared/pages/CmsPage.vue';
import getHelp from 'javascripts/video_conferencing/components/shared/enquiries/getHelp.vue';
import NotFound from 'javascripts/video_conferencing/components/shared/pages/NotFound.vue';
// import and set all font awesome icons from here
import fontAwesomeImports  from 'javascripts/video_conferencing/font-awesome-imports.js'



// import qs from 'qs';
// import VueFlatPickr from 'vue-flatpickr-component';
// import { pagination } from 'javascripts/mixins/pagination.js';
// import { search_form } from 'javascripts/mixins/search_form.js';
// import { search_filters } from 'javascripts/mixins/search_filters.js';
// import { google_places } from 'javascripts/mixins/google_places.js';
// import { flatpickr_config } from 'javascripts/mixins/flatpickr_config.js';
// import { scroll_to_top } from 'javascripts/mixins/scroll_to_top.js';
// import organisationResult from 'javascripts/components/organisationResult.js';

// import 'flatpickr/dist/flatpickr.css';
// Vue.use(VueFlatPickr, {
//   name: 'flat-pickr'
// });

Vue.use(VueRouter);


document.addEventListener('DOMContentLoaded', () => {

  if (document.getElementById("video-conferencing")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    // used for invite a friend form
    Vue.use(VueReCaptcha, { siteKey: '6LdOHqQUAAAAANoJ4ENbvHZkd2G6U68tX4lYDOsV' });


    const VideoConferencing = Vue.component('video_conferencing', {
      mixins: [
      ],
      components: {
        videoConferencingHome,
        videoConferencingRoomList,
        videoConferencingRoom,
        videoConferencingScheduledRoomList,
        RegistrationForm,
        InviteAFriend,
        VueReCaptcha,
        Buddies,
        NowPlayHomepage,
        VideoCategoryPage,
        EditProfile,
        NowPlaySearchPage,
        Login,
        vueFooter,
        ForgottenPassword,
        ResetPassword,
        CmsPage,
        getHelp,
        NotFound
        
      },
      props: [
      ],
      data: function () {
        return {
          test: 1,
        };
      },
      methods: {
        loadData() {
        },
      },
      computed: {
      },
      created() {
      },
      watch: {

        // solves the focus issue we get when tabbing trough conent and visiting a different page
        // e.g being stuck on footer links
        '$route'(to, from) {

          if(from.path !== to.path) {
            let page = undefined;
            page = document.querySelector('#video-conferencing');
            page.focus();
          }

        }
      }

    })

    const router = new VueRouter({
      mode: 'hash',
      routes: [
        { path: '/', name: 'home', component: videoConferencingHome },
        { path: '/cafe', component: videoConferencingRoomList },
        { path: '/join/:uuid', component: videoConferencingRoom },
        { path: '/book/:uuid', component: videoConferencingBookingForm },
        { path: '/sessions', component: videoConferencingScheduledRoomList },
        { path: '/register', name: 'register', component: RegistrationForm },
        { path: '/invite-friends', component: InviteAFriend },
        { path: '/buddies', component: Buddies },
        { path: '/now_play/', component: NowPlayHomepage },
        { path: '/now_play/latest', component: LatestVideos },
        { path: '/now_play/popular', component: PopularVideos },
        { path: '/now_play/categories', component: VideoCategories },
        { path: '/now_play/categories/:page', component: VideoCategoryPage, props: true, name: 'VideoCategoryPage'},
        { path: '/edit-profile', component: EditProfile },
        { path: '/now_play/search/:term', component: NowPlaySearchPage, props: true, name: "NowPlaySearchPage" },
        { path: '/login', name: 'login', component: Login },
        { path: '/forgotten-password', component: ForgottenPassword},
        { path: '/reset-password/:token', component: ResetPassword},
        { path: '/pages/:path', component: CmsPage, props: true },
        { path: '/enquiries/get-help', component: getHelp},
        { path: '/*', component: NotFound}

      ]
    })

    new Vue({ router }).$mount('#video-conferencing');
  }
});
