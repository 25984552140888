<template>
    <section class="incident-report-form__overlay">

        <section class="incident-report-form">

          <div class="incident-report-form__close">
            <h1> 
                <font-awesome-icon icon="fas fa-bullhorn" aria-hidden="true" role="presentation"/>
                Incident Reporting Tool
            </h1>
            <button  @click="closeReport">
                <font-awesome-icon icon="fas fa-close"  class="close-icon" aria-label="close" role="button"/>
                 close 
            </button>
          </div>
            
            <form>
                
                <span>
                    <fieldset>
                        <label for="name"> Your Name: <span class="required-star"> * </span> </label>
                        <input type="name" name="name" required aria-required="true" autocomplete="name" aria-autocomplete="name" v-model="reporter_name" placeholder="e.g Alex H.">
                    </fieldset>

                    <fieldset>
                        <label for="email"> Your Email: <span class="required-star"> * </span></label>              
                        <input type="email" name="email" required aria-required="true" autocomplete="email" aria-autocomplete="email" v-model="reporter_email" placeholder="e.g alex@gmail.com">
                    </fieldset>
                </span>

                <span>
                    <fieldset>
                        <label for="date">Incident Date: <span class="required-star"> * </span> </label>
                        <input type="date" name="date" required aria-required="true" v-model="date">
                    </fieldset>


                    <fieldset>            
                        <label for="time"> Incident Time: <span class="required-star"> * </span> </label>
                        <input type="time" name="time" required aria-required="true" v-model="time">
                    </fieldset>
                </span>

                <fieldset>       
                    <label for="sessionName"> Session Name: <span class="required-star"> * </span></label>
                    <input type="text" name="sessionName" required aria-required="true" v-model="session_name" placeholder="please enter session name">
                </fieldset>

                <fieldset>
                    
                    <label for="incidentType">Please select the incident type: <span class="required-star"> * </span></label>
                    <select name="incidentType" required aria-required="true" v-model="incident_type">
                        <option value="Select" disabled>Select incident type</option>
                        <option value="Harrassment"> Harrassment</option>
                        <option value="Safeguarding"> Safeguarding </option>
                        <option value="Other">Other</option>
                    </select>

                </fieldset>

                <fieldset>
                    <label for="incidentDescription">Please provide details about the incident: <span class="required-star"> * </span></label>
                    <textarea name="incidentDescription" required aria-required="true" rows="8" cols="50" v-model="incident_details" placeholder="please enter details"></textarea>
                </fieldset>


                <div class="validation-message" v-if="shouldShowValidationMessage"> 
                    <p> Please fill out missing fields </p>
                </div>

                <div class="validation-message" v-if="reportNotSent">
                    <p> Unable to send. Please try again</p>
                </div>

                <button class="incident-report__submit" @click.prevent="sendReport"> submit </button>

            </form>

        </section>

    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "IncidentReport",
    props: ['room', 'participant'],

    data() {
       return {
            time: new Date().toLocaleTimeString(),
            date: new Date().toISOString().substr(0, 10),
            reporter_name: '',
            reporter_email: '',
            session_name: '',
            session_uuid: '',
            incident_type: 'Select',
            incident_details: '',
            shouldShowValidationMessage: false,
            reportNotSent: false,
       }
    },

    mounted() {
        this.getAdminDetails();
        this.getSessionName();
    },

    methods: {
        sendReport() {
           
            // If we need to send authenticity parameters, pseudo-code is:
            // var authenticity_token = javascript to get meta[name=csrf-token], content attribute


            if(
                this.reporter_name !==  '' &&
                this.reporter_email !==  '' &&
                this.session_name !==  '' &&
                this.session_uuid !== '' &&
                this.incident_type !== 'Select' &&
                this.incident_details !==  ''

            ) {

             axios.post('/enquiry_forms/incident_reporting/enquiries.json', {
                    // authenticity_token: authenticity_token,
                    enquiry_form_id: "incident_reporting",
                    enquiry: {
                        name: this.reporter_name,
                        email: this.reporter_email,
                        incident_date: this.date,
                        incident_time: this.time,
                        session_name: this.session_name,
                        incident_type: this.incident_type,
                        incident_description: this.incident_details,
                    }
                }).then((response) => {
                    if(response.statusText == "OK") {
                        this.closeReport(); // this may be changed? Need to add code for if status is not ok
                    } else {
                        this.reportNotSent = !this.reportNotSent;
                    }
                })
            } else {
                this.shouldShowValidationMessage = !this.shouldShowValidationMessage;
            }

        },

        closeReport() {
            this.$emit('hideIncidentReportModule');
        },

        getSessionName() {
            if(this.room.name) {
                this.session_name =  this.room.name;
                this.session_uuid = this.room.uuid;
            }
        },

        getAdminDetails() {
            if(this.participant.name && this.participant.email) {
                this.reporter_name = this.participant.name;
                this.reporter_email = this.participant.email;
            }
        }
    },
    computed: {
        
    }

}
</script>