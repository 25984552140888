<template>
    <div 
        class="now-play__single-category"
        :class=" {
            'orange-background'                 : category.name.includes('Fab'), 
            'green-background'                  : category.name.includes('Children') ,
            'blue-background'                   : category.name.includes('Training'),
            'white-background'                  : category.isOther === true,  
            'additional-category-background'    :  isAdditionalCategory
        }"
        @click="handleClick(category)"
    >
        <div :class="[ category.isOther ? 'now-play__single-category--title-modified' : 'now-play__single-category--title'] ">
            <h3> {{ category.name }} 

                <i v-show="category.isOther">                    
                     <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation" />
                </i>
            </h3>
        </div>

        <div class="now-play__single-category--description" v-if="category.description !== '' || category.description !== null">
            <p> {{ category.description }}</p>
        </div>
    </div>

       
</template>
    
<script>
export default {
    name: 'SingleCategory',
    props: ['category', 'toBeExpanded'],

    methods: {
        sendEmitToExpandCategory(category) {
            this.$emit('expandCategory', category);
        },


        viewIndividualCategory(category){
            this.$router.push({ name: 'VideoCategoryPage', params: { category: category, page: category.name } });
        },

        handleClick(category) {

            if(this.toBeExpanded) {
                this.sendEmitToExpandCategory(category);
            } else {
                this.viewIndividualCategory(category);
            }

        }
    },
    computed: {
        isAdditionalCategory() {
            return (
                !this.category.name.includes('Fab') &&
                !this.category.name.includes('Children') &&
                !this.category.name.includes('Training') &&
                !this.category.isOther
            )
        }
    }

}
</script>

<style scoped lang="scss">

    .orange-background {
        background-color: #d37d0d;
    }

    .green-background {
        background-color: #88c054;
    }

    .blue-background {
        background-color: #1e93be;
    }

    .white-background {
        background-color: #ededed;
    }

    .additional-category-background {
        background: rgb(72, 7, 120);
        color: white;
        width: 300px;

        .now-play__single-category--description p {
            color: white
        }

    // .now-play__single-category--title {
    //     // border: 1px solid #333333;
    // }

    }

</style>