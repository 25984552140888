<template>
  <div class="video-conferencing__cafe">
    <logoBar></logoBar>
    <div class="new-navigation-buttons__cafe">
      <navigationButtons :modifiedStylingForCafe="true" ></navigationButtons>
    </div>
    <loading class="cafe__loading" v-show="!loaded" :customText="'Loading cafe...'"></loading>
    <main id="content">
      <section class="cafe-section cafe__closed" v-if="loaded && !isOpen">
        <h1 class="cafe-section__heading">Sorry, the cafe is currently closed</h1>
        <p class="video-conferencing__upcoming--title">
          The cafe is open weekdays, between 10am and 4pm.
        </p>
        <div class="cafe__closed-container">
            <img class="cafe__closed-image" src="/images/hartlepoolatn/closed-sign.jpg" alt="Sign on window saying 'Sorry, we are closed'">
        </div>
      </section>
      <template v-if="loaded && isOpen">
        <section class="cafe-section" :class="participant.website_user_id ? 'logged-in' : ''">

          <section class="cafe-section__buddy-notification-banners" v-if="onlineBuddies.length > 0">
            <div class="cafe-section__buddy-notification" v-for="(buddy, index) in onlineBuddies" :key="index">
              <p class="video-conferencing__upcoming--title">
                {{buddy.name}} is online! <router-link v-if="buddy.last_video_conferencing_room_id !== null" :to="'/join/' + buddy.last_video_conferencing_room_id"> Join them here </router-link>
              </p>
            </div>
          </section>

          <div v-if="rooms.length > 0">
            <h2 class="cafe-section__heading">There are chats taking place in the cafe</h2>
            <p class="cafe-section__text">Choose 'join' on any available conversation to say hello.</p>
          </div>

          <div v-else>
            <h1 class="cafe-section__heading">Start a chat in the cafe</h1>
          </div>

          <div class="cafe-view-buttons">
            <button class="cafe-list-view" :class="cafeMode ? 'cafe-list-view--cafe' : 'cafe-list-view--list'" @click="toggleView">
              <img class="cafe-view-buttons__img" v-if="cafeMode" src="/images/video_conferencing/list-grey.png" alt="">
              <img class="cafe-view-buttons__img" v-if="!cafeMode" src="/images/video_conferencing/list-white.png" alt="">
              List view {{ !cafeMode ? '(this view)' : ''}}
            </button>
            <button class="cafe-cafe-view" :class="cafeMode ? 'cafe-cafe-view--cafe' : 'cafe-cafe-view--list'" @click="toggleView">
              <img class="cafe-view-buttons__img" v-if="cafeMode" src="/images/video_conferencing/cafe-white.png" alt="">
              <img class="cafe-view-buttons__img" v-if="!cafeMode" src="/images/video_conferencing/cafe-grey.png" alt="">
              Cafe view {{ cafeMode ? '(this view)' : ''}}
            </button>

          </div>

          <div :class="cafeMode ? 'cafe-container' : 'list-container'">
            <img class="cafe-image" src="/images/hartlepool/cafe-blue-v2.png" alt="Cafe @the Hub with tables and chairs and a coffee counter">

            <div :class="cafeMode ? 'cafe-rooms-wrapper' : 'list-rooms-wrapper'">
              <section class="cafe-room" v-for="room in rooms" v-bind:key="room">
                <div class="room__intro" v-if="!cafeMode">
                  <p>Chat with: {{ roomParticipantsInWords(room) }}</p>
                </div>
                <div class="room__row">
                  <div class="room__participants-row">
                    <div class="room__participant" v-for="(participant, index) in room.current_participants" v-bind:key="participant">
                      <template v-if="index <= 2">
                        <img class="room__avatar-image" :src="participant_profile_image(room.room_participants, participant)" :alt="participant_profile_image(room.room_participants, participant) === '/images/video_conferencing/buddies_notification.png' ? '' : 'Profile image of ' + participant">
                        <p class="room__name">{{ participant }}</p>
                      </template>
                    </div>
                  </div>
                  <div class="room__additional-number" v-if="room.current_participants.length > 3">
                    <span>+ {{ room.current_participants.length - 3 }}</span>
                  </div>
                </div>
                <div v-if="cafeMode" class="room__col"> <!-- display for screen sizes below 992px -->
                  <span class="cafe-room__number">{{ room.current_participants.length }}</span>
                  <span class="cafe-room__text">people chatting</span>
                </div>
                <div class="room__row--alt">
                  <div class="room__duration">
                    <span
                      v-if="room.current_participants.length > 5"
                    >
                      This room is now full. <br/>Try another room or start your own chat!
                    </span>
                    <span
                      v-else-if="room.closed"
                    >
                      <strong>This room is private</strong>
                    </span>
                    <span
                      v-else-if="room.current_participants.length > 1 && room.current_participants.length < 6"
                    >
                      Calling for around {{ room.duration_text }}
                    </span>
                    <span
                      v-else
                    >
                      Available to chat!
                    </span>
                  </div>
                  <div
                    class="room__join"
                    v-if="room.current_participants.length < 6 && !room.closed"
                  >
                    <router-link
                      :to="'/join/' + room.uuid"
                      tag="button"
                      class="video-conferencing-room__button--on room__join--button"
                    >
                      Join
                    </router-link>
                  </div>
                </div>
                <div class="room__row--blocks" v-if="current_user_isAdmin">
                    <span> {{ block_count(room) }} Blocks</span>
                </div>
              </section>
              <hr v-if="!cafeMode" class="video-conferencing__session-divider">
              <section v-if="cafeMode" class="cafe-room--participant">
                <img class="room__avatar-image" :src="[participant.profile_image_url ? participant.profile_image_url : '/images/video_conferencing/buddies_notification.png']" alt="a grey coloured silouette of a person">
                <span class="cafe-room__text">You!</span>
                <button @click="createRoom" class="video-conferencing-room__button--on room__join--button">
                  start your own chat
                </button>
              </section>
            </div> <!-- cafe-rooms-wrapper -->
          </div> <!-- cafe-container -->
        </section>

        <section class="cafe-join-section" :class="cafeMode ? 'cafe-mode' : 'list-mode'">
          <h3 class="cafe-join-section__heading">{{ !cafeMode && rooms.length < 1 ? 'You' : 'Or you' }} can start your own conversation.</h3>
          <p class="cafe-join-section__text">Starting your own chat means others can see you are available in our cafe.</p>
          <div class="cafe-join-section__inner">
            <button @click="createRoom" class="video-conferencing-room__button--on room__join--button">
              start a new chat
            </button>
            <router-link to="/enquiries/get-help" class="cafe-join-section__link">Get more help about starting a new chat</router-link>
          </div>
        </section>
      </template>
    </main>

  </div>
</template>

<script>
import axios from 'axios';
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import Loading from './components/shared/loading.vue';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';

export default {
  name: 'videoConferencingRoomList',
  components: {
    logoBar,
    Loading,
    navigationButtons
  },
  data: function () {
    return {
      isOpen: true,
      loaded: false,
      rooms: [],
      cafeMode: true,
      participant: {
        website_user_id: undefined,
        buddies: [],
        is_adhoc_admin: undefined,
        is_scheduled_admin : undefined,
        profile_image_url: ''
      },
      onlineBuddies: []
    }
  },
  methods: {
    loadBuddies() {
      axios.get('/friendships.json').then((response) => {

       this.participant.buddies = response.data.friends;


       this.participant.buddies.forEach(friend => {
         if (friend.currently_in_lobby_or_room && this.isAlreadyOnlineBuddy(friend) == false) {
           this.onlineBuddies.push(friend);
         }
       });
      })
    },

    block_count(room) {
      if(room.reported_participants) {
        return room.reported_participants.length;
      } else {
        return 0
      }
    },

    isAlreadyOnlineBuddy(friend) {

      if(this.onlineBuddies.length > 0) {

        this.onlineBuddies.forEach(buddy => {

          if (buddy.id === friend.id) {
            return true
          } else {
            return false
          }
        })
      } else {
            return false
      }
    },
    recordUserIsInTheLobby() {
      axios.put('/website_users/current_user.json', {  in_lobby: "yes"  }).then((response) => {
      })
    },
    // not needed - rails checks to see if last in lobby/room timestamp within last 1 minute
    // recordUserHasLeftTheLobby() {
    //   axios.put('/website_users/current_user.json', {  in_lobby: "no"  }).then((response) => {
    //   })
    // },
    loadRooms() {
      axios.get("/video_conference/rooms.json").then((response) => {
        this.isOpen = response.data.is_open
        this.rooms = response.data.rooms;

        if(!this.loaded) {
          this.loaded = true;
        }
      })
    },
    loadCurrentWebsiteUser() {
      axios.get("/website_users/current_user.json").then((response) => {
        if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
          this.participant.website_user_id = response.data.current_website_user.id;
          this.participant.is_adhoc_admin = response.data.current_website_user.is_adhoc_admin;
          this.participant.is_scheduled_admin = response.data.current_website_user.is_scheduled_admin;
          this.participant.profile_image_url = response.data.current_website_user.profile_image_url;
        }
      })
    },
    createRoom() {
      axios.post("/video_conference/rooms.json").then((response) => {
        this.$router.push({ path: '/join/' + response.data.room.uuid })
      })
    },
    toggleView() {
      this.cafeMode = !this.cafeMode;
    },
    futureAlert() {
      alert("Future work - to be added later");
    },
    roomParticipantsInWords(room) {
      if(room.current_participants.length <= 3 ) {
        return room.current_participants.join(', ')
      } else {
        let first_three = room.current_participants.slice(0, 3).join(', ');
        let additional = room.current_participants.length - 3;
        let other = " other";
        if(additional > 1) {
          other = " others..."
        }
        return first_three + " and " + additional + other
      }
    },
    participant_profile_image(room_participants, participant) {
      let image_url = '/images/video_conferencing/buddies_notification.png';
      if(room_participants) {
        room_participants.forEach(room_participant => {
          if (Object.values(room_participant).includes(participant)) {
            if(room_participant.image_url) {
              image_url = room_participant.image_url
            }
          }
        })
      }
      return image_url;
    }
  },

  computed: {
    current_user_isAdmin() {
      if (this.participant.is_adhoc_admin || this.participant.is_scheduled_admin) {
        return true
      } else {
        return false
      }
    }
  },
  created: function () {
    this.loadRooms();
    this.loadCurrentWebsiteUser();
    this.recordUserIsInTheLobby();
    this.loadBuddies();

    this.lobbyPolling = setInterval(function () {
      this.loadRooms();
    }.bind(this), 11000);

    this.buddyPolling = setInterval(function () {
      this.loadBuddies();
    }.bind(this), 23000);

    this.userPolling = setInterval(function () {
      this.recordUserIsInTheLobby();
    }.bind(this), 19000);
  },

  beforeDestroy() {
    // this.recordUserHasLeftTheLobby(); // not needed - rails checks to see if last in lobby/room timestamp within last 1 minute
    clearInterval(this.lobbyPolling);
    clearInterval(this.userPolling);
    clearInterval(this.buddyPolling);
  }
}
</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1000px) {

      .video-conferencing-room__navigation {
        justify-content: center;
      }

  }

  .cafe__loading {
    min-height: 80vh;
    position: unset;

    @media screen and (max-width: 1100px) {
      min-height: 45vh;
    }
  }



</style>
