<template>
  <div class="video-conferencing__booking-container">
    <logoBar></logoBar>
    <navigation-buttons class="video-conferencing__navigation-buttons"></navigation-buttons>

    <section class="video-conferencing__booking-container--inner">
        <h1 class="video-conferencing__booking-heading" v-if="!booked">
          <font-awesome-icon icon="fas fa-calendar-day" aria-hidden="true" role="presentation"/>
          Booking Form
        </h1>

        <div v-if="room && !booked" class="video-conferencing__booking-container-form" >

          <h2 class="video-conferencing__booking-form-heading">Booking for {{ room.name }}</h2>

          <form @submit.prevent="submitBooking">

            <label class="video-conferencing__booking-form-label">
              Name: <input class="video-conferencing__booking-form-input" type="text" name="name" v-model="participant.name" placeholder="e.g Anna Smith">
            </label>

            <label class="video-conferencing__booking-form-label">
              Email: <input class="video-conferencing__booking-form-input" type="email" name="email" id="email" v-model="participant.email" placeholder="e.g anna@email.com">
            </label>

            <label class="video-conferencing__booking-form-label checkbox-label" v-if="!participant.website_user_id">
              Please confirm you agree to <router-link to="/pages/terms-and-disclaimer"> terms of use </router-link> by ticking this box:
              <input class="video-conferencing__booking-form-input--checkbox" type="checkbox" name="terms" id="terms" v-model="participant.terms" aria-label="accept terms">
            </label>

            <div class="error-message" v-if="errorMessage !=='' " role="alert" aria-live="polite">
              <p> {{ errorMessage }}</p>
            </div>

            <button class="video-conferencing__booking-form-button" type="submit">
              Book
            </button>
          </form>
        </div>

        <div class="video-conferencing__booking-complete" v-if="booked">
          <h1 class="video-conferencing__booking-complete-heading">Thank you for booking.</h1>
          <p class="video-conferencing__booking-complete-text mb-4">You will shortly receive an email.</p>
          <router-link class="video-conferencing__booking-complete-link" :to="'/'" >
            Return to All Together Now Homepage
          </router-link>
        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios';
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import NavigationButtons from './components/shared/navigationButtons.vue';

export default {
  name: 'videoConferencingBookingForm',
  components: {
    logoBar,
    NavigationButtons
  },
  data: function () {
    return {
      room: undefined,
      participant: {
        website_user_id: undefined,
        name: "",
        email: "",
        terms: false,
      },
      booked: false,
      errorMessage: ''
    }
  },
  methods: {
    loadRoom() {
      axios.get("/video_conference/rooms/" + this.$route.params.uuid + ".json").then((response) => {
        this.room = response.data;

        if (this.room.current_user_booked) {
          this.booked = true
        }
      })
    },
    loadCurrentWebsiteUser() {
      axios.get("/website_users/current_user.json").then((response) => {
        if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
          this.participant.website_user_id = response.data.current_website_user.id
          this.participant.name = response.data.current_website_user.name
          this.participant.email = response.data.current_website_user.email
          this.participant.terms = true
        }
      })
    },
    submitBooking() {
      if(this.participant.name !== '' && this.participant.email !=='' && this.participant.terms == true) {
        axios.post("/video_conference/rooms/" + this.$route.params.uuid + "/participants.json", { participant: this.participant }).then((response) => {
          if (response.data.success) {
            this.booked = true
          } else {
            // Error?
          }
        })
      } else {
        this.errorMessage = "Please fill out all fields and accept terms of use to continue"

        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
      }
    }
  },
  created: function () {
    this.loadRoom();
    this.loadCurrentWebsiteUser();
  },
}
</script>


<style lang="scss" scoped>


.video-conferencing__booking-heading {
  color: rgb(72, 7, 120);
}

.video-conferencing__booking-form-button {
  color: #333333;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;

  a {
    color: #D2014A;
    margin-left: 3px;
    margin-right: 3px;
  }

  @media (max-width: 680px){
    display: block;
    text-align: center!important;
  }
}
  input[type=checkbox] {
    height: 25px;
    width: 25px;
  }

  .error-message {
    background: #fca44b;
    padding: 10px;
    color: white;
    margin-bottom: 20px;

    p {
      margin-bottom: 0;

    }
  }

  form {
    max-width: 800px;
  }
</style>
