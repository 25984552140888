<template>
    <div class="now-play__categories-page--wrapper">
        <logoBar></logoBar>
        <div class="now-play__categories-page">
            <navigationButtons></navigationButtons>
            <loading v-if="!isLoaded"></loading>

            <a @click="$router.go(-1)" class="page-navigation__back">
                <font-awesome-icon icon="fas fa-angle-double-left" aria-hidden="true" role="presentation"/>
                go back
            </a>

            <main class="now-play__categories-page__list" id="content">

                <div class="now-play__categories-page--title">
                    <header>
                        <span class="line dark-line"></span>
                        <h1> All Categories</h1>
                    </header>
                </div>

                <div class="now-play__categories-page--videos">
                    <single-category
                        v-for="(category, index) in categories" 
                        :key="index"
                        :category="category"
                        :toBeExpanded="false"
                    > </single-category>
                </div>

            </main>
        </div>
    </div>
</template>

<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import Loading from '../shared/loading.vue';
import SingleCategory from './secondary/SingleCategory.vue';

export default {
    name: 'VideoCategories',

    components: {
        logoBar,
        navigationButtons,
        Loading,
        SingleCategory,
    },

    data() {
        return {
            currentUser: [],
            categories: [],
            isLoggedIn: false,
            isLoaded: false,
        }
    },
    
    created() {
        this.loadCategories();
        this.loadCurrentWebsiteUser();
    },

    methods: {
        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.isLoaded   = true;
                    this.currentUser = response.data.current_website_user;
                } else {
                    this.isLoaded   = true;
                }
            })
        }, 

        loadCategories() {
            axios.get('documents.json?page=1&per_page=10&search_document%5Bterm%5D=').then((response) => {
                
                this.categories = response.data.category_schemes[0].categories;
               
            });
        },

        futureAlert() {
            alert('To be added at a later point');
        },
    },
}
</script>


<style lang="scss" scoped>

    .now-play__categories-page {
        min-height: 800px;
        margin-top: 10px;
        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }
    .now-play__categories-page__list {

    }

    .now-play__categories-page--title {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        vertical-align: middle;
        header {
            display: flex;
            align-items: center;
            

            h1 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }

    .now-play__categories-page--videos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
</style>