var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"video-conferencing-room__navigation",class:{
      'logged-in': _vm.participant.website_user_id,
      modifiedStylingForHomepage: _vm.modifiedStylingForHomepage,
      modifiedStylingForSessions: _vm.modifiedStylingForSessions,
    }},[(_vm.currentWebsiteUser.logged_in && _vm.$route.path == '/')?_c('section',{staticClass:"welcome-message mb-2 ml-2"},[_c('p',[_vm._v("Welcome, "+_vm._s(_vm.currentWebsiteUser.name))]),_vm._v(" "),_c('img',{attrs:{"src":[
          _vm.participant.profile_image_url
            ? _vm.participant.profile_image_url
            : '/images/video_conferencing/buddies_notification.png' ],"alt":'Profile image of' + _vm.currentWebsiteUser.name}})]):_vm._e(),_vm._v(" "),(_vm.$route.path != '/')?_c('router-link',{staticClass:"video-conferencing-room__navigation-button",class:{
        'modified-styling__sessions-button': _vm.modifiedStylingForSessions,
      },attrs:{"to":'/'}},[_c('img',{attrs:{"src":"/images/video_conferencing/home.png","alt":"house","aria-hidden":"true","role":"presentation"}}),_vm._v(" "),_c('p',[_vm._v("home")])]):_vm._e(),_vm._v(" "),(_vm.$route.path != '/edit-profile' && _vm.currentWebsiteUser.logged_in)?_c('router-link',{staticClass:"video-conferencing-room__navigation-button",class:{
        'modified-styling__sessions-button': _vm.modifiedStylingForSessions,
      },attrs:{"to":'/edit-profile'}},[_c('img',{attrs:{"src":"/images/video_conferencing/profile.png","alt":"user","aria-hidden":"true","role":"presentation"}}),_vm._v(" "),_c('p',[_vm._v("your profile")])]):_vm._e(),_vm._v(" "),(_vm.currentWebsiteUser.logged_in && _vm.$route.path != '/buddies')?_c('router-link',{staticClass:"\n        video-conferencing-room__navigation-button\n        component__online-buddies\n      ",class:{
        'modified-styling__sessions-button': _vm.modifiedStylingForSessions,
      },attrs:{"to":'/buddies'}},[_c('span',{class:[
          _vm.onlineBuddies.length > 0 ? 'hasOnlineBuddies' : 'noOnlineBuddies' ]},[_c('img',{attrs:{"src":"/images/video_conferencing/buddies.png","alt":"users","aria-hidden":"true","role":"presentation"}}),_vm._v(" "),(_vm.onlineBuddies.length > 0)?_c('sub',{staticClass:"component__online-buddies__notification",class:{ modifiedStylingForCafe: _vm.modifiedStylingForCafe }},[_vm._v("\n          "+_vm._s(_vm.onlineBuddies.length)+"\n        ")]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v("your buddies")])]):_vm._e(),_vm._v(" "),(_vm.$route.path != '/enquiries/get-help')?_c('router-link',{staticClass:"video-conferencing-room__navigation-button",class:{
        'modified-styling__sessions-button': _vm.modifiedStylingForSessions,
      },attrs:{"to":"/enquiries/get-help"}},[_c('img',{attrs:{"src":"/images/video_conferencing/help.png","alt":"question mark inside of a bubble","aria-hidden":"true","role":"presentation"}}),_vm._v(" "),_c('p',[_vm._v("get help")])]):_vm._e(),_vm._v(" "),(_vm.currentWebsiteUser.logged_in)?_c('button',{staticClass:"\n        video-conferencing-room__navigation-button\n        extra-navigation-button\n      ",on:{"click":function($event){return _vm.logout()}}},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-sign-out","aria-hidden":"true","role":"presentation"}}),_vm._v("\n      "+_vm._s(_vm.logoutButton.text)+"\n    ")],1):_vm._e(),_vm._v(" "),(!_vm.currentWebsiteUser.logged_in)?_c('router-link',{staticClass:"\n        video-conferencing-room__navigation-button\n        extra-navigation-button\n      ",attrs:{"to":"/register"}},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-user","aria-hidden":"true","role":"presentation"}}),_vm._v("\n      create account\n    ")],1):_vm._e(),_vm._v(" "),(!_vm.currentWebsiteUser.logged_in)?_c('router-link',{staticClass:"\n        video-conferencing-room__navigation-button\n        extra-navigation-button\n      ",attrs:{"to":"/login"}},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-sign-in-alt","aria-hidden":"true","role":"presentation"}}),_vm._v("\n      login\n    ")],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }