<template>
    <section class="video-conferencing__upcoming-wrapper" v-show="upcomingRooms.length">
     <!-- <p class="video-conferencing__upcoming--title" v-show="shouldShowNotification"> Upcoming Sessions:</p> -->
      <article v-for="(room, index) in upcomingRooms" :key="index" :class="shouldShowNotification ? 'video-conferencing__upcoming' : 'video-conferencing__upcoming--hidden' " >
        <div class="video-conferencing__upcoming--details">
          <router-link :to="'/join/' + room.uuid">
              {{ room.name }} at {{room.casual_start_time}} - Join Session
          </router-link>
        </div>
          <div class="video-conferencing__upcoming--close">
          <p @click="hideNotification" aria-label="close" role="button"> X </p>
        </div>
      </article>
    </section>
</template>

<script>
    export default {
        name: 'notificationBanner',
        props: ['upcomingRooms'],
        data() {
            return {
                shouldShowNotification: true,
            }
        },
        methods: {
            hideNotification() {
                this.shouldShowNotification = false;
            },
        }
    }
</script>


<style lang="scss" scoped>

  .video-conferencing__upcoming {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #74ba51;

    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;

    //same as the width of room container
    width: 65%;
    cursor: pointer;


    &:hover {
      transition: all 200ms ease-in-out;
      transform: translateY(-3px);
      background: lighten( #74ba51,  10%);
    }

    &--title {
      font-size: 18px;
      font-weight: bold;
      color: rgb(33, 37, 41);
    }

    &--hidden {
      display: none;
    }

    &--details {
      position: relative;
      left: 10px;

      display: inline-block;
      width: 100%;

      a {
        color: white;
        margin: 0;
        font-size: 1.2em;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        display: block;

         &:hover {
          color: rgb(33, 37, 41);
          text-decoration: underline;
        }
      }
    }

    &--close {
      position: relative;
      right: 10px;

       p {
        color: white;
        margin: 0;
        font-weight: bolder;
        border: 1px solid white;
        padding: 2px 8px;
        border-radius: 100%;

        &:hover {
          color: rgb(33, 37, 41);
          border: 1px solid rgb(33, 37, 41);
        }
      }

    }
  }

  @media (max-width: 768px) {
    .video-conferencing__upcoming {
      width: 100%;
    }
  }
</style>
